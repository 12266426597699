import { connect } from "react-redux";
import { getCurrentTaskParams } from "../selectors/tasks";
import { nextStep } from "../actions/index";
import maximize from "../util/maximize";

// connect the state to the provided "stepper" component
export const connectStepper = connect(
  ({ currentTask: { step, taskOrderIndex }, tasks: { order } }) => {
    const iteration = order[taskOrderIndex].iteration;
    const stepProp = step ? { step } : {};
    const iterationProp = iteration ? { iteration } : {};
    return { ...stepProp, ...iterationProp };
  }
);

export const connectInstructions = connect(
  (state) => ({ iteration: getCurrentTaskParams(state).iteration }),
  (dispatch) => ({
    onNext: () => {
      maximize();
      dispatch(nextStep());
    },
  })
);
