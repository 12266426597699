import { connect } from "react-redux";
import { nextTask } from "../actions/index";
import { getCurrentTaskParams } from "../selectors/tasks";
import Test from "../components/test";

const stateToProps = (state) => {
  const {
    authentication: { authenticated },
    landingShown,
    preLoginShown,
  } = state;
  const {
    finished = false,
    showTaskCompletion = false,
    task,
  } = getCurrentTaskParams(state) || {};
  return {
    task,
    showPreLogin: !preLoginShown,
    showLogin: !authenticated && preLoginShown,
    showLanding: !landingShown,
    showFinished: finished,
    showTaskCompletion,
  };
};

const dispatchToProps = { notImplementedClick: nextTask };

export default connect(stateToProps, dispatchToProps)(Test);
