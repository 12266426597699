const language = (t) => {
  var current_language_strings = window.language_strings;

  if (window.language_strings && t in current_language_strings) {
    return current_language_strings[t][0];
  } else {
    return "";
  }
};

export default language;
