import { PropTypes } from 'prop-types';
import TaskTitle from './task-title';

// steps should be an array
// where each step is an array of 2 columns.
// Use null for the step to skip it.
// It's a bit strange, but make it easier to convert from the silverlight app.
const Instructions = ({ title, steps, id, className, video }) => {
  const rows = steps.reduce((a, e, i) => {
    if (!e) {
      return a;
    }

    let [col1, col2] = e;
    return a.concat(
      <div key={i} className={`row step-${i + 1}`}>
        <div className="small-8 columns">{col1}</div>
        <div className="small-4 columns">{col2}</div>
      </div>
    );
  }, []);

  const myClass = [
    ...(className == null ? [] : [className]),
    'instructions',
  ].join(' ');

  return (
    <div id={id} className={myClass}>
      {title && <TaskTitle>{title}</TaskTitle>}
      {rows}
      {video != null ? <div key={steps.length + 1} className={`row step-${steps.length + 1}`}>
        <div className="small-12 columns">
          <h3>Instructional Video:</h3>
          {video}
        </div>
      </div> : ''}
    </div>
  );
};

Instructions.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
};

export default Instructions;
