import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const OneBackInstructions = (props) => {
  const steps = [
    [
      language('one-back-instructions-message-1'),
      <div id="oneback-animation">
        <img
          className="oneback-instructions-small"
          src={require('../../../images/tasks/oneback/oneback1.jpg')}
        />
        <img
          className="oneback-instructions-small"
          src={require('../../../images/tasks/oneback/oneback2.jpg')}
        />
        <img
          className="oneback-instructions-small"
          src={require('../../../images/tasks/oneback/oneback3.jpg')}
        />
      </div>,
    ],
    [
      <span
        dangerouslySetInnerHTML={{
          __html: language('one-back-instructions-message-2'),
        }}
      ></span>,
      <img src={require('../../../images/instructions/one-back-2.png')} />,
    ],
    [
      language('one-back-instructions-message-3'),
      <img src={require('../../../images/instructions/one-back-3.png')} />,
    ],
    [
      language('one-back-instructions-message-4'),
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
  ];

  const onNextFunc = () => {
    SendEchoSignal('FFFG');
    props.onNext();
  };

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/CiTYTSuTEJY?si=3Qiy75S8pPoloIjL"
      title="One Back Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={32} onKey={onNextFunc}>
      <Instructions
        title={language('one-back-title')}
        steps={steps}
        id="oneback-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default OneBackInstructions;

OneBackInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
