import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const FacialRecognitionInstructions = (props) => {
  const steps = [
    [
      language('facial-recognition-instructions-message-1'),
      <div id="facialrecognition-animation">
        <img src={require('../../../images/instructions/Facial01.png')} />
        <img src={require('../../../images/instructions/Facial02.png')} />
        <img src={require('../../../images/instructions/Facial03.png')} />
      </div>,
    ],
    [
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: language('facial-recognition-instructions-message-2'),
          }}
        ></span>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: language('facial-recognition-instructions-message-3'),
          }}
        ></span>
      </div>,
      <div className="image-with-text">
        <img src={require('../../../images/instructions/facial-2-a.png')} />
        <span>{language('facial-recognition-image-text')}</span>
      </div>,
    ],
    [
      <p
        dangerouslySetInnerHTML={{
          __html: language('facial-recognition-instructions-message-4'),
        }}
      ></p>,
      <img
        src={require('../../../images/instructions/right-arrow-starts.png')}
      />,
    ],
  ];

  const onNextFunc = () => {
    SendEchoSignal('FFFGGG');
    props.onNext();
  };

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/3zQ_cvip9tU?si=LR7EfzJN7ln6SZFR"
      title="Facial Recognition Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={39} onKey={onNextFunc}>
      <Instructions
        title={language('facial-recognition-title')}
        steps={steps}
        id="facialrecognition-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default FacialRecognitionInstructions;

FacialRecognitionInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
