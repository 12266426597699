import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import language from '../util/language';
import { capitalizeFirst } from '../util/text';
import STEPS, { INSTRUCTIONS, PRESENTATION } from '../constants/emotion-steps';
import Instructions from '../containers/emotion/instructions';
import Presenter from '../containers/emotion/presenter';

const Emotion = ({ iteration, step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case PRESENTATION:
      content = <Presenter />;
      break;
  }
  return (
    <DocumentTitle
      title={title(
        `${language('emotion-title')} (${language(
          'emotion-title-helper'
        )} ${capitalizeFirst(iteration)})`
      )}
    >
      {content}
    </DocumentTitle>
  );
};

export default Emotion;

Emotion.propTypes = {
  iteration: PropTypes.string.isRequired,
  step: PropTypes.oneOf(STEPS).isRequired,
};
