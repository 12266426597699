import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import SendEchoSignal from '../../util/send_echo_signal';

const SPACE_BAR = 32;

class OneBackTests extends XlntTestComponent {
  start() {
    SendEchoSignal('G');
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 1000;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return event.keyCode == SPACE_BAR && !this.ignoreKeys;
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.needsRestart = true;
  }

  render() {
    return this.state.active ? (
      <div id="oneback" className={this.state.active ? 'active' : ''}>
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="oneback">
          <img
            id="oneback-image"
            src={require(`../../../images/tasks/oneback/${this.props.image}.jpg`)}
          />
        </div>
      </div>
    ) : null;
  }
}

export default OneBackTests;

OneBackTests.propTypes = {
  image: PropTypes.string.isRequired,

  timeToHit: PropTypes.number.isRequired, // in seconds

  // called when the user presses the key
  // args: reactionTime and tapDuration
  onHit: PropTypes.func.isRequired,

  // no arguments
  onTimeout: PropTypes.func.isRequired,

  // called when the first test begins, no arguments
  onStart: PropTypes.func,
};
