import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

const tokenizePhrase = (phrase) => phrase.trim().split(' ');
const translatePhrase = (phrase) => {
  var cleanedPhrase = phrase
    .toLowerCase()
    .replace(/-/g, '')
    .replace(/ /g, '-')
    .replace(/\./g, '')
    .replace(/'/g, '')
    .replace(/"/g, '');
  return language('sentence-list-' + cleanedPhrase);
};

function* rephrase(phrase) {
  for (let word of phrase) {
    yield word;
  }
}

class SentenceTests extends XlntTestComponent {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 0,
      phrase: tokenizePhrase(
        translatePhrase(this.props.tests[this.props.testIndex].text)
      ),
    };
  }

  triggerHit(keyUpTime, response, id) {
    this.stop();
    const tapDuration = (keyUpTime - this.keyDownTime) / 1000;
    const reactionTime = (this.keyDownTime - this.startedAt) / 1000;
    this.props.onHit(id, response, reactionTime, tapDuration);
  }

  start() {
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 1000;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        SendEchoSignal('H');
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return (
      [LEFT_ARROW, RIGHT_ARROW].includes(event.keyCode) && !this.ignoreKeys
    );
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(
          now,
          event.keyCode == LEFT_ARROW ? 'no' : 'yes',
          this.props.tests[this.props.testIndex].id
        );
        if (event.keyCode == LEFT_ARROW) {
          SendEchoSignal('H');
        } else {
          SendEchoSignal('G');
        }
      }
    }
  }

  presentSentence(phrase) {
    let gen = rephrase(phrase);
    let next = gen.next();
    const loop = () => {
      this.setState({ currentWord: next.value });
      next = gen.next();
      if (!next.done) {
        SendEchoSignal('F');
        setTimeout(() => {
          this.setState({ currentWord: null });
          setTimeout(() => {
            loop();
          }, this.props.timeBetweenWords * 1000);
        }, this.props.timeShowingWords * 1000);
      } else {
        this.start();
      }
    };

    loop();
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('keydown', this);
    window.addEventListener('keyup', this);
    this.presentSentence(this.state.phrase);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.testIndex != nextProps.testIndex) {
      this.needsRestart = true;
      this.setState({
        phrase: tokenizePhrase(
          translatePhrase(nextProps.tests[nextProps.testIndex].text)
        ),
      });
    }
  }

  componentDidUpdate() {
    if (this.needsRestart) {
      this.needsRestart = false;
      this.presentSentence(this.state.phrase);
    }
  }

  render() {
    return (
      <div id="sentence" className={this.state.active ? 'active' : ''}>
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="sentence">
          <span className="sentence-word">{this.state.currentWord}</span>
        </div>
      </div>
    );
  }
}

export default SentenceTests;

SentenceTests.propTypes = {
  // array of tests, where the value is the test id and the text
  tests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,

  // index of the current test in the tests array
  testIndex: PropTypes.number.isRequired,

  timeBetweenWords: PropTypes.number.isRequired, // in seconds
  timeShowingWords: PropTypes.number.isRequired, // in seconds
  timeToHit: PropTypes.number.isRequired,

  // called when the user presses the key
  // args: id, response ("yes" or "no"), reactionTime, and tapDuration
  onHit: PropTypes.func.isRequired,

  // called when the user fails to respond before the timeToHit
  // args: id
  onTimeout: PropTypes.func.isRequired,

  // called when the first test begins, no arguments
  onStart: PropTypes.func,
};
