import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import CustomProgressBar from './custom-progress-bar';
import Button from './button';
import title from '../util/title';
import language from '../util/language';

let tokenDetected = false;

const Login = ({
  username,
  password,
  authenticating = false,
  onChangeUsername,
  onChangePassword,
  onLoginWithUserToken,
  onSubmit,
}) => {
  if (!tokenDetected) {
    const searchParams = new URLSearchParams(location.search);
    const userToken = searchParams.get('user');
    if (userToken) {
      onLoginWithUserToken(userToken);
      tokenDetected = true; // Set the flag to true after executing the effect
    }
  }
  let progress;
  let submitButton;

  if (authenticating) {
    progress = (
      <div>
        <CustomProgressBar completed={100} height="30px" />
        <p className="text-center">{language('login-in-progress')}</p>
      </div>
    );
    submitButton = <Button disabled>{language('sign-in-button-label')}</Button>;
  } else {
    progress = null;
    submitButton = <Button>{language('sign-in-button-label')}</Button>;
  }

  return (
    <DocumentTitle title={title('Log In')}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        id="login"
      >
        <div className="row instructions">
          <div className="small-12 columns">
            {progress}
            <p>{language('login-message-1')}</p>
            <p>{language('login-message-2')}</p>
          </div>
        </div>
        <div className="row">
          <div className="small-4 columns">
            <label htmlFor="username" className="right inline">
              {language('email-label')}
            </label>
          </div>
          <div className="small-8 columns">
            <input
              type="email"
              name="username"
              value={username || ''}
              required
              autoFocus
              onChange={(e) => onChangeUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="small-4 columns">
            <label htmlFor="password" className="right inline">
              {language('password-label')}
            </label>
          </div>
          <div className="small-8 columns">
            <input
              type="password"
              name="password"
              value={password || ''}
              required
              onChange={(e) => onChangePassword(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="small-4 columns"></div>
          <div className="small-8 columns">{submitButton}</div>
        </div>
      </form>
    </DocumentTitle>
  );
};

Login.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  authenticating: PropTypes.bool,
  onChangeUsername: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Login;
