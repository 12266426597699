// this is a mess, but replicates the silverlight layout

import { PropTypes } from 'prop-types';
import Instructions from '../instructions';
import KeyListener from '../key-listener';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const firstSteps = (hasList) => {
  const respond = <p>{language('word-general-instructions-message-1')}</p>;

  const tapToBegin = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-general-instructions-message-2'),
      }}
    ></p>
  );

  const instructions_word_list = language(
    'word-instructions-image-word-list'
  ).split(',');
  const startImage = (
    <img src={require('../../../images/instructions/right-arrow-starts.png')} />
  );

  const word4 = (
    <div className="word-instructions-image-1">
      <div>
        <span className="image-left">
          {language('word-instructions-image-word-left')}
        </span>
        <span className="image-right">
          {language('word-instructions-image-word-right')}
        </span>
      </div>
    </div>
  );

  const right = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-instructions-message-tap-right'),
      }}
    ></p>
  );

  const left = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-instructions-message-tap-left'),
      }}
    ></p>
  );

  const word4c = (
    <div className="word-instructions-image-2">
      <div>
        <span className="image-left">
          {language('word-instructions-image-word-left')}
        </span>
        <span className="image-right">
          {language('word-instructions-image-word-right')}
        </span>
      </div>
      <img src={require('../../../images/instructions/word-4c-a.png')} />
    </div>
  );

  const withList = [
    [
      language('word-with-list-message-1'),
      <div id="word-animation">
        <span>{instructions_word_list[0]}</span>
        <span>{instructions_word_list[1]}</span>
        <span>{instructions_word_list[2]}</span>
      </div>,
    ],
    [language('word-with-list-message-2'), word4],
  ];
  const withoutList = [[language('word-with-pairs-message-1'), word4]];
  return [
    ...(hasList ? withList : withoutList),
    [right, null],
    [left, word4c],
    [respond, null],
    [tapToBegin, startImage],
  ];
};

const WordInstructions = ({
  timeShown = 1,
  iteration,
  hasList = true,
  onNext,
}) => {
  const respond = <p>{language('word-general-instructions-message-1')}</p>;
  const tapToBegin = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-general-instructions-message-2'),
      }}
    ></p>
  );
  const startImage = (
    <img src={require('../../../images/instructions/right-arrow-starts.png')} />
  );

  const right = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-instructions-message-tap-right'),
      }}
    ></p>
  );

  const left = (
    <p
      dangerouslySetInnerHTML={{
        __html: language('word-instructions-message-tap-left'),
      }}
    ></p>
  );

  const word4c = (
    <div className="word-instructions-image-2">
      <div>
        <span className="image-left">
          {language('word-instructions-image-word-left')}
        </span>
        <span className="image-right">
          {language('word-instructions-image-word-right')}
        </span>
      </div>
      <img src={require('../../../images/instructions/word-4c-a.png')} />
    </div>
  );

  const repeatedSteps = [
    [
      <div>
        {right}
        {left}
      </div>,
      word4c,
    ],
    [
      <div>
        {respond}
        {tapToBegin}
      </div>,
      startImage,
    ],
  ];

  const title_with_iteration = language('word-title').replace(
    '[[index]]',
    iteration
  );

  const steps = timeShown === 1 ? firstSteps(hasList) : repeatedSteps;
  const title = timeShown === 1 ? title_with_iteration : null;
  const className = hasList ? 'with-list' : 'without-list';

  const onNextFunc = () => {
    if (timeShown === 1) {
      let echoSignal;
      switch (iteration) {
        case '1':
          echoSignal = 'FGH';
          break;
        case '2':
          echoSignal = 'FGHH';
          break;
        case '3':
          echoSignal = 'FGHHH';
          break;
        case '4':
          echoSignal = 'FFFGGGH';
          break;
        default:
          echoSignal = 'FGH';
          break;
      }
      SendEchoSignal(echoSignal);
    }
    onNext();
  };

  const video =
    iteration == 1 ? (
      <iframe
        width="800"
        height="540"
        src="https://www.youtube.com/embed/AJn-HIKkCkw?si=9yMlN73vi2IcrjqF"
        title="Word Iteration 1 Test Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ) : iteration == 4 ? (
      <iframe
        width="800"
        height="540"
        src="https://www.youtube.com/embed/Kclm8OGKl-A?si=45Vd33iJOq78qXpL"
        title="Word Iteration 4 Test Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ) : null;

  return (
    <KeyListener keyCode={39} onKey={onNextFunc}>
      <Instructions
        id="word-test-instruction-page"
        video={video}
        steps={steps}
        title={title}
        className={className}
      />
    </KeyListener>
  );
};

WordInstructions.propTypes = {
  timeShown: PropTypes.oneOf([1, 2]),
  iteration: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired,
  hasList: PropTypes.bool,
};

export default WordInstructions;
