import {
  NEXT_QUESTIONNAIRE_PAGE,
  PREVIOUS_QUESTIONNAIRE_PAGE,
  SET_QUESTIONNAIRE_INSTRUCTIONS_SHOWN,
} from "../../constants/action-types";
import { questionnairePageCount } from "../../util/questionnaire";

const questionnaireParams = (
  state,
  { type, shown },
  { questionnaire: { questions } }
) => {
  let { page = 0, instructionsShown = false } = state;
  if (type == PREVIOUS_QUESTIONNAIRE_PAGE && page > 0) {
    page -= 1;
  } else if (type == NEXT_QUESTIONNAIRE_PAGE) {
    const pageCount = questionnairePageCount(questions);
    if (page < pageCount - 1) {
      page += 1;
    }
  }
  if (type == SET_QUESTIONNAIRE_INSTRUCTIONS_SHOWN) {
    instructionsShown = shown;
  }
  return { ...state, page, instructionsShown };
};

export default questionnaireParams;
