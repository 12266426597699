import KeyListener from '../key-listener';
import Instructions from '../instructions';
import { capitalizeFirst } from '../../util/text';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const transposeEmotion = (emotion) => {
  switch (emotion) {
    case 'happy':
      return 'sad';
    case 'sad':
      return 'happy';
    case 'angry':
      return 'afraid';
    case 'afraid':
      return 'neutral';
    case 'neutral':
      return 'angry';
    default:
      return 'neutral';
  }
};

const getEchoSignal = (emotion) => {
  switch (emotion) {
    case 'happy':
      return 'FGGH';
    case 'sad':
      return 'FGGHH';
    case 'angry':
      return 'FGGHHHH';
    case 'afraid':
      return 'FGGHHH';
    case 'neutral':
      return 'FGGHHHHH';
    default:
      return 'FGGHHHHH';
  }
};

const EmotionInstructions = (props) => {
  const emotion_str = language('emotion-' + props.iteration);
  const emotion_str_instructions_text = language(
    'emotion-' + props.iteration + '-instruction-text'
  );
  const title = language('emotion-identification-title').replace(
    '[[emotion]]',
    capitalizeFirst(emotion_str)
  );

  const steps = [
    [
      `${language('emotion-identification-instructions-message-1')}`,
      <div id="emotion-animation">
        <img
          className="emotion-instruction-narrow"
          src={require('../../../images/tasks/emotion/1-afraid.jpg')}
        />
        <img
          className="emotion-instruction-narrow"
          src={require('../../../images/tasks/emotion/2-happy.jpg')}
        />
        <img
          className="emotion-instruction-narrow"
          src={require('../../../images/tasks/emotion/3-angry.jpg')}
        />
      </div>,
    ],
    [
      <p
        dangerouslySetInnerHTML={{
          __html: language('emotion-identification-instructions-message-2'),
        }}
      ></p>,
      <img
        className="emotion-instruction-wide"
        src={require(`../../../images/instructions/emotion-correct-${transposeEmotion(
          props.iteration
        )}.png`)}
      />,
    ],
    [
      <p
        dangerouslySetInnerHTML={{
          __html: language(
            'emotion-identification-instructions-message-3'
          ).replace('[[emotion]]', emotion_str_instructions_text),
        }}
      ></p>,
      <img
        className="emotion-instruction-wide"
        src={require(`../../../images/instructions/emotion-except-${props.iteration}.png`)}
      />,
    ],
    [
      language('emotion-identification-instructions-message-4'),
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
  ];

  const video =
    props.iteration == 'happy' ? (
      <iframe
        width="800"
        height="540"
        src="https://www.youtube.com/embed/cm7hyJw3L30?si=G0mEZsDJbvy4i-LD"
        title="Emotion Identification Test Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    ) : null;

  const onNextFunc = () => {
    SendEchoSignal(getEchoSignal(props.iteration));
    props.onNext();
  };
  return (
    <KeyListener keyCode={32} onKey={onNextFunc}>
      <Instructions
        title={title}
        steps={steps}
        id="emotion-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default EmotionInstructions;
