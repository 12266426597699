import { Component } from 'react';

// presentation of words to be used in the test
// they're presented one at a time
class XlntTestComponent extends Component {
  constructor(props) {
    super(props);
    this.startedAt = null;
    this.ignoreKeys = true;
    this.keyDownTime = null;
    this._isMounted = false;

    var timeLeft = 0;
    if (props.timeToHit) {
      timeLeft = props.timeToHit;
    }
    this.state = { timeLeft: timeLeft };
  }

  start() {}

  triggerTimeout() {
    this.stop();
    if (this.props.onTimeout) {
      this.props.onTimeout();
    }
  }

  triggerHit(keyUpTime) {
    this.stop();
    const tapDuration = (keyUpTime - this.keyDownTime) / 1000;
    const reactionTime = (this.keyDownTime - this.startedAt) / 1000;
    this.props.onHit(reactionTime, tapDuration);
  }

  stop() {
    this.setState({ active: false, timeLeft: 0 });
    this.ignoreKeys = true;
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.start();
    window.addEventListener('keydown', this);
    window.addEventListener('keyup', this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
    window.removeEventListener('keydown', this);
    window.removeEventListener('keyup', this);
  }

  componentDidUpdate() {
    if (this.needsRestart) {
      this.needsRestart = false;
      this.start();
    }
  }
  render() {
    return <div></div>;
  }
}

export default XlntTestComponent;
