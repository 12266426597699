import { ADD_REPORT, REMOVE_REPORT } from "../constants/action-types";

export default (state = {}, { type, ...action }) => {
  switch (type) {
    case ADD_REPORT:
      return { ...state, [action.reference]: action.report };
    case REMOVE_REPORT: {
      const newState = Object.assign({}, state);
      delete newState[action.reference];
      return newState;
    }
    default:
      return state;
  }
};
