import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, {
  INSTRUCTIONS,
  TESTS,
} from '../constants/facial-recognition-steps';
import { FACIAL_RECOGNITION_INTERVAL } from '../constants/task-parameters';
import Instructions from '../containers/facial-recognition/instructions';
import Presenter from '../containers/facial-recognition/presenter';

const FacialRecognition = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = <Presenter timeToHit={FACIAL_RECOGNITION_INTERVAL} />;
      break;
  }
  return (
    <DocumentTitle title={title('Facial Recognition Test')}>
      {content}
    </DocumentTitle>
  );
};

export default FacialRecognition;

FacialRecognition.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
