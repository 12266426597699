const Button = ({ className, children, ...rest }) => {
  const myClassName = className ? `${className} radius` : 'radius';
  const myProps = { className: myClassName, ...rest };

  return (
    <div className="button-wrapper">
      <button {...myProps}>{children}</button>
    </div>
  );
};

export default Button;
