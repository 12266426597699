import { PropTypes } from 'prop-types';
import Instructions from '../instructions';
import KeyListener from '../key-listener';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const SentenceInstructions = (props) => {
  const instructions_sentence = language(
    'sentence-instructions-animation-sentence'
  );
  const instructions_sentence_splitted = instructions_sentence
    .trim()
    .split(' ');
  const steps = [
    [
      language('sentence-instructions-message-1'),
      <div id="sentence-animation">
        <span>{instructions_sentence_splitted[0]}</span>
        <span>{instructions_sentence_splitted[1]}</span>
        <span>{instructions_sentence_splitted[2]}</span>
        <span>{instructions_sentence_splitted[3]}</span>
        <span>{instructions_sentence_splitted[4]}</span>
      </div>,
    ],
    [
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: language('sentence-instructions-message-2'),
          }}
        ></span>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: language('sentence-instructions-message-3'),
          }}
        ></span>
      </div>,
      <div className="sentence-instruction-wide-container">
        <img
          className="sentence-instruction-wide"
          src={require('../../../images/instructions/sentence-3-a.png')}
        />
        <span className="sentence-without-last-word">
          {instructions_sentence_splitted[0]}{' '}
          {instructions_sentence_splitted[1]}{' '}
          {instructions_sentence_splitted[2]}{' '}
          {instructions_sentence_splitted[3]}
        </span>
        <span className="sentence-last-word">
          {instructions_sentence_splitted[4]}
        </span>
        <span className="not-make-sense">
          {language('sentence-instructions-not-make-sense')}
        </span>
        <span className="make-sense">
          {language('sentence-instructions-make-sense')}
        </span>
      </div>,
    ],
    [
      <div>
        <span>{language('sentence-instructions-message-4')}</span>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: language('sentence-instructions-message-5'),
          }}
        ></span>
      </div>,
      <img
        src={require('../../../images/instructions/right-arrow-starts.png')}
      />,
    ],
  ];

  const onNextFunc = () => {
    SendEchoSignal('FFFGG');
    props.onNext();
  };

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/ph_GZf10TeI?si=L_fZthFPrXd9OdgJ"
      title="Sentence Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={39} onKey={onNextFunc}>
      <Instructions
        title={language('sentence-title')}
        steps={steps}
        id="sentence-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default SentenceInstructions;

SentenceInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
