import { PropTypes } from 'prop-types';
import Preload from 'react-preload';
import FacialRecognitionTests from './tests';

const imagesToUrls = (images) => {
  let urls = [];
  for (let imageSet of images) {
    for (let image of imageSet) {
      urls.push(require(`../../../images/tasks/emotion/${image}.jpg`));
    }
  }

  return urls;
};

const loadingIndicator = <div>Loading...</div>;

const FacialRecognitionPresenter = (props) => (
  <Preload
    loadingIndicator={loadingIndicator}
    images={imagesToUrls(props.tests)}
  >
    <FacialRecognitionTests
      onStart={props.testIndex === 0 ? props.onStart : null}
      timeToHit={props.timeToHit}
      onTimeout={props.onTimeout}
      onHit={props.onHit}
      key={props.tests[props.testIndex]}
      firstImage={props.tests[props.testIndex][0]}
      secondImage={props.tests[props.testIndex][1]}
    />
  </Preload>
);

export default FacialRecognitionPresenter;

FacialRecognitionPresenter.propTypes = {
  // array of tests, where the value is a pair of image names
  // without the "jpg" extensions
  tests: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,

  // index of the current test in the tests array
  testIndex: PropTypes.number.isRequired,
  timeToHit: PropTypes.number.isRequired, // in centiseconds
  onTimeout: PropTypes.func.isRequired,
  onHit: PropTypes.func.isRequired,
  onStart: PropTypes.func,
};
