import { connect } from "react-redux";
import SendReportComponent from "../components/send-report";
import { restart, submitReport } from "../actions/index";
import { loginURL } from "../constants/api";

export default connect(
  ({ currentTask: { errorMessage, errorMessageDetail, complete } }) => ({
    errorMessage,
    errorMessageDetail,
    complete,
  }),
  (dispatch) => ({
    onContinue: () => {
      if (window.opener) {
        window.opener.location.href = loginURL;
        window.close();
      } else {
        window.location.href = loginURL;
      }
    },
    onRetry: () => dispatch(submitReport()),
  })
)(SendReportComponent);
