export const FORM_FIELDS = {
  LOGIN: 'login',
  PASSWORD: 'password',
  SECURITYTAG: 'securitytag',
  REPORT_XML: 'reportxml',
  REPORT_REFERENCE: 'reportreference',
  LANGUAGE_KEY: 'language_key',
};
export const HTTP_METHOD = {
  POST: 'POST',
};
export const HTTP_RESPONSE = {
  MIN: 200,
  MAX: 300,
};
export const URL = {
  ENDPOINTS: {
    AUTHORIZATION: 'api/v2/user_authentication.xml',
    TEST_SESSION: 'api/v1/test_session.xml',
    REPORT_SUBMISSION: 'api/v1/report.xml',
    GET_LANGUAGE: 'api/v1/cns_cog_test_languages.xml',
  },
  PORT: '80',
  PROTOCOL: 'http',
};

export let loginURL;
if (__DEV__) {
  URL.BASE = 'staging.xlntbrain.com';
  loginURL = 'https://staging.xlntbrain.com/';
  URL.PROTOCOL = 'https';
  URL.PORT = 443;
}

if (__STAGING__) {
  URL.BASE = 'staging.xlntbrain.com';
  loginURL = 'https://staging.xlntbrain.com/';
  URL.PROTOCOL = 'https';
  URL.PORT = 443;
}

if (__PRODUCTION__) {
  URL.BASE = 'app.xlntbrain.com';
  loginURL = 'https://app.xlntbrain.com/';
  URL.PROTOCOL = 'https';
  URL.PORT = 443;
}

// allow the url base to be set through an environment variable
if (API_URL_BASE) {
  URL.BASE = API_URL_BASE;
  loginURL = `https://${API_URL_BASE}/`;
}
