import { PropTypes } from 'prop-types';

const TaskTitle = ({ children }) => (
  <div className="row">
    <div className="small-12 columns">
      <h2>{children}</h2>
    </div>
  </div>
);

TaskTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TaskTitle;
