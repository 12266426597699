import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import CustomProgressBar from './custom-progress-bar';
import Button from './button';
import title from '../util/title';
import language from '../util/language';

export const Complete = ({ onContinue }) => (
  <div>
    <p>{language('report-has-been-sent-message')}</p>
    <Button onClick={onContinue}>
      {language('go-to-sign-in-button-label')}
    </Button>
  </div>
);

export const Sending = () => (
  <div>
    <p>{language('sending-report-message')}</p>
    <CustomProgressBar completed="100" height="30px" speed="0.01" />
  </div>
);

export const SendError = ({ message, detail, onRetry }) => {
  let detailNode;
  if (detail) {
    detailNode = (
      <div>
        <label htmlFor="details">Error details:</label>
        <textarea rows={5} cols={80} id="details" value={detail} readOnly />
      </div>
    );
  }
  return (
    <div>
      <p>{message}</p>
      <Button onClick={onRetry}>Try Again</Button>
      {detailNode}
    </div>
  );
};

const SendReport = ({
  complete,
  errorMessage,
  errorMessageDetail,
  onContinue,
  onRetry,
}) => {
  let content;
  if (complete) {
    content = <Complete onContinue={onContinue} />;
  } else if (errorMessage) {
    content = (
      <SendError
        message={errorMessage}
        detail={errorMessageDetail}
        onRetry={onRetry}
      />
    );
  } else {
    content = <Sending />;
  }

  return (
    <DocumentTitle title={title('Finished')}>
      <div id="send-report">
        <p>
          <strong>{language('finished-test-message')}</strong>
        </p>
        {content}
      </div>
    </DocumentTitle>
  );
};

export default SendReport;

SendReport.propTypes = {
  onContinue: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorMessageDetail: PropTypes.string,
  onRetry: PropTypes.func.isRequired,
};
