import xml2js from "xml2js";
const Sentry = window.Sentry;

export const parseXml = (xml) =>
  new Promise((fulfill, reject) => {
    let parseString = xml2js.parseString;
    parseString(xml, { async: true }, (error, data) => {
      if (error) {
        Sentry.captureMessage("XLNTBrain API retrieveLanguage parseXML failed!");
        Sentry.captureMessage(`XML body text: ${xml}`);
        reject(error);
      } else {
        fulfill(data);
      }
    });
  });

export const buildXml = (object) =>
  new Promise((fulfill, reject) => {
    let builder = new xml2js.Builder();
    try {
      var jsonString = JSON.stringify(object);
      var objectWithoutNull = JSON.parse(jsonString);
      let xml = builder.buildObject(objectWithoutNull);

      fulfill(xml);
    } catch (error) {
      reject(error);
    }
  });
