import { connect } from "react-redux";
import FastForwarderComponent from "../../components/dev-tools/fast-forwarder";
import { nextTask, nextStep, setLandingShown } from "../../actions/index";
import { getCurrentTaskParams, getNextStep } from "../../selectors/tasks";

const stateToProps = (state) => {
  const { task } = getCurrentTaskParams(state) || {};
  const hasNextStep = !!getNextStep(state);
  return {
    task,
    hasNextStep,
    landingShown: state.landingShown,
  };
};

const mergeProps = (
  { task, hasNextStep, landingShown },
  {
    nextTask: boundNextTask,
    nextStep: boundNextStep,
    setLandingShown: boundSetLandingShown,
  },
  otherProps
) => {
  const nextTaskProps = task
    ? { nextTask: landingShown ? boundNextTask : boundSetLandingShown }
    : {};
  const nextStepProps = hasNextStep ? { nextStep: boundNextStep } : {};
  return { ...nextTaskProps, ...nextStepProps, ...otherProps };
};

const FastForwarder = connect(
  stateToProps,
  { nextTask, setLandingShown, nextStep },
  mergeProps
)(FastForwarderComponent);

export default FastForwarder;
