import screenfull from "screenfull";

// Safari doesn't support keyboard input in full screen
export const FULLSCREEN_SUPPORTED =
  screenfull.enabled &&
  (navigator.userAgent.includes("Chrome") ||
    !navigator.userAgent.includes("Safari"));

export const getQueryStringParam = (param_key, default_value = "") => {
  const query = window.location.search.substring(1).split("&");
  var b = {};
  for (var i = 0; i < query.length; ++i) {
    var p = query[i].split("=", 2);
    if (p.length == 1) b[p[0]] = "";
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return param_key in b ? b[param_key] : default_value;
};
