import { connect } from "react-redux";
import Instructions from "../../components/questionnaire/instructions";
import { setQuestionnaireInstructionsShown } from "../../actions/index";

const stateToProps = (_) => ({});

const dispatchToProps = {
  onNext: setQuestionnaireInstructionsShown,
};

export default connect(stateToProps, dispatchToProps)(Instructions);
