import { connect } from "react-redux";
import Questions from "../../components/questionnaire/questions";
import {
  nextTask,
  setTaskResultDetails,
  markTaskResultsStart,
  markTaskResultsEnd,
  nextQuestionnairePage,
  previousQuestionnairePage,
  setQuestionnaireInstructionsShown,
  setShowTaskCompletion,
} from "../../actions/index";
import {
  questionnairePageCount,
  questionnairePages,
} from "../../util/questionnaire";
import {
  YES_NO_QUESTION,
  ZERO_TO_FOUR_QUESTION,
} from "../../constants/question-types";

const stateToProps = ({
  currentTask: { page },
  tasks: {
    questionnaire: { questions },
    results: { questionnaire: { details = [] } = {} },
  },
}) => {
  const pageQuestions = questionnairePages(questions)[page];
  const pageValues = pageQuestions.reduce(
    (a, e) => ({
      ...a,
      [e.id]: (details.find((d) => d.id === e.id) || {}).response,
    }),
    {}
  );
  return {
    page,
    questions: pageQuestions,
    values: pageValues,
    pageCount: questionnairePageCount(questions),
  };
};

const questionChangeHandler = (id, response) =>
  setTaskResultDetails(
    {
      id,
      response,
    },
    (d) => d.id === id
  );

const dispatchToProps = (dispatch) => ({
  onQuestionChange: (id, response) =>
    dispatch(questionChangeHandler(id, response)),
  dispatch,
});

// returns true if the question is answered
const answered = (question, value) =>
  (question.responseType === YES_NO_QUESTION && value != null) ||
  (question.responseType == ZERO_TO_FOUR_QUESTION && value >= 0) ||
  (question.responseType != ZERO_TO_FOUR_QUESTION && value);

const onNext = (page, pageCount, dispatch, questions, values) => {
  if (!questions.every((q) => answered(q, values[q.id]))) {
    return () => alert("All the questions should be answered");
  }
  if (page < pageCount - 1) {
    return () => dispatch(nextQuestionnairePage());
  }
  return () => {
    dispatch(markTaskResultsEnd());
    dispatch(setShowTaskCompletion(true, nextTask()));
  };
};

const onPrevious = (page, dispatch) =>
  page > 0
    ? () => dispatch(previousQuestionnairePage())
    : () => dispatch(setQuestionnaireInstructionsShown(false));

const mergeProps = (
  { page, pageCount, ...stateProps },
  { dispatch, ...dispatchProps },
  otherProps
) => {
  const { questions, values } = stateProps;

  return {
    ...stateProps,
    ...dispatchProps,
    onStart: () => dispatch(markTaskResultsStart()),
    onNext: onNext(page, pageCount, dispatch, questions, values),
    onPrevious: onPrevious(page, dispatch),
    ...otherProps,
  };
};

export default connect(stateToProps, dispatchToProps, mergeProps)(Questions);
