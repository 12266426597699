import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const PreLogin = ({ onLoad }) => {
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="row" onLoad={onLoad}>
      Loading....
    </div>
  );
};

PreLogin.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default PreLogin;
