import * as taskTypes from "../constants/task-types";
import wordSteps from "../constants/word-steps";
import emotionSteps from "../constants/emotion-steps";
import timeSteps from "../constants/time-steps";
import tappingSteps from "../constants/tapping-steps";
import stopOrGoSteps from "../constants/stop-or-go-steps";
import oneBackSteps from "../constants/one-back-steps";
import sentenceSteps from "../constants/sentence-steps";
import facialRecognitionSteps from "../constants/facial-recognition-steps";

const TASK_STEPS = {
  [taskTypes.EMOTION_IDENTIFICATION_TASK]: emotionSteps,
  [taskTypes.TIME_TASK]: timeSteps,
  [taskTypes.TAPPING_TASK]: tappingSteps,
  [taskTypes.STOP_OR_GO_TASK]: stopOrGoSteps,
  [taskTypes.ONE_BACK_TASK]: oneBackSteps,
  [taskTypes.SENTENCE_TASK]: sentenceSteps,
  [taskTypes.FACIAL_RECOGNITION_TASK]: facialRecognitionSteps,
};

// params should be from store.tasks[taskType] or
// store.tasks[taskType][iteration] if there is an iteration
const taskSteps = (task, params) => {
  if (task === taskTypes.WORD_TASK) {
    return wordSteps(params);
  }
  return TASK_STEPS[task] || [];
};

export const firstStep = (task, params) => taskSteps(task, params)[0];

export const nextStep = (task, currentStep, params) => {
  const steps = taskSteps(task, params);
  const stepIndex = steps.indexOf(currentStep);
  if (stepIndex === -1 || stepIndex === steps.length - 1) {
    return null;
  }
  return steps[stepIndex + 1];
};
