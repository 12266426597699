import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import language from '../util/language';
import Questions from '../containers/questionnaire/questions';
import Instructions from '../containers/questionnaire/instructions';

const Questionnaire = ({ showInstructions = false }) => (
  <DocumentTitle title={title(language('questionnaire-title'))}>
    {showInstructions ? <Instructions /> : <Questions />}
  </DocumentTitle>
);

Questionnaire.propTypes = {
  showInstructions: PropTypes.bool,
};

export default Questionnaire;
