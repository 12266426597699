import * as types from "../constants/action-types";

export const nextQuestionnairePage = () => ({
  type: types.NEXT_QUESTIONNAIRE_PAGE,
});

export const previousQuestionnairePage = () => ({
  type: types.PREVIOUS_QUESTIONNAIRE_PAGE,
});

export const setQuestionnaireInstructionsShown = (shown = true) => ({
  type: types.SET_QUESTIONNAIRE_INSTRUCTIONS_SHOWN,
  shown,
});
