import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const STOPORGOBYWORD = 'by_word';
const STOPORGOBYCOLOR = 'by_color';
const STOPORGOWORD = 'word';
const STOPORGOCOLOR = 'color';
const STOPORGOITERATIONS = [STOPORGOBYWORD, STOPORGOBYCOLOR];

const wordForIteration = (iteration) => {
  switch (iteration) {
    case STOPORGOBYCOLOR:
      return STOPORGOCOLOR;
    case STOPORGOBYWORD:
      return STOPORGOWORD;
  }
};

const titleTextForIteration = (iteration) => {
  switch (iteration) {
    case STOPORGOBYCOLOR:
      return (
        '(' + language('stop-or-go-instructions-by-color-except-message') + ')'
      );
    case STOPORGOBYWORD:
      return (
        '(' + language('stop-or-go-instructions-by-word-except-message') + ')'
      );
  }
};

const getEchoSignal = (iteration) => {
  switch (iteration) {
    case STOPORGOBYCOLOR:
      return 'FFGGH';
    case STOPORGOBYWORD:
      return 'FFGG';
  }
};

const instructionsHitForIteration = (iteration) => {
  switch (iteration) {
    case STOPORGOBYCOLOR: {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: language('stop-or-go-instructions-by-color-message-1'),
          }}
        ></span>
      );
    }

    case STOPORGOBYWORD: {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: language('stop-or-go-instructions-by-word-message-1'),
          }}
        ></span>
      );
    }
  }
};

const instructionsNotHitForIteration = (iteration) => {
  switch (iteration) {
    case STOPORGOBYCOLOR: {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: language('stop-or-go-instructions-by-color-message-2'),
          }}
        ></span>
      );
    }

    case STOPORGOBYWORD: {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: language('stop-or-go-instructions-by-word-message-2'),
          }}
        ></span>
      );
    }
  }
};

const StopOrGoInstructions = (props) => {
  var correctText = null;
  var incorrectText = null;
  var correctColor = null;
  var incorrectColor = null;
  if (props.iteration == STOPORGOBYCOLOR) {
    correctText = language('stop-or-go-stop');
    incorrectText = language('stop-or-go-go');
    correctColor = 'blue';
    incorrectColor = 'red';
  } else {
    correctText = language('stop-or-go-go');
    incorrectText = language('stop-or-go-stop');
    correctColor = 'red';
    incorrectColor = 'red';
  }

  const steps = [
    [
      <span
        dangerouslySetInnerHTML={{
          __html: language('stop-or-go-instructions-message-1'),
        }}
      ></span>,
      <div id="stop-or-go-animation">
        <span className="stop blue">{language('stop-or-go-stop')}</span>
        <span className="go red">{language('stop-or-go-go')}</span>
        <span className="stop blue">{language('stop-or-go-stop')}</span>
        <span className="stop blue">{language('stop-or-go-stop')}</span>
        <span className="go red">{language('stop-or-go-go')}</span>
      </div>,
    ],
    [
      instructionsHitForIteration(props.iteration),
      <div className="image-with-text">
        <img
          src={require(`../../../images/instructions/stop-or-go-correct.png`)}
        />
        <span className={correctColor}>{correctText}</span>
      </div>,
    ],
    [
      instructionsNotHitForIteration(props.iteration),
      <div className="image-with-text">
        <img
          src={require(`../../../images/instructions/stop-or-go-incorrect.png`)}
        />
        <span className={incorrectColor}>{incorrectText}</span>
      </div>,
    ],
    [
      <div>
        <span>{language('stop-or-go-instructions-message-2')}</span>
        <br />
        <span>{language('stop-or-go-instructions-message-3')}</span>
      </div>,
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
  ];

  const onNextFunc = () => {
    SendEchoSignal(getEchoSignal(props.iteration));
    props.onNext();
  };

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/-sCPZzZ17UE?si=re9UCpkdKbTX9iTQ"
      title="Stop or Go Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={32} onKey={onNextFunc}>
      <Instructions
        title={`${language('stop-or-go-title')} ${titleTextForIteration(
          props.iteration
        )}`}
        steps={steps}
        id="stop-or-go-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default StopOrGoInstructions;

StopOrGoInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
  iteration: PropTypes.oneOf(STOPORGOITERATIONS).isRequired,
};
