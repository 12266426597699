import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../button';
import Question, { QUESTION_PROPS } from './question';
import TaskTitle from '../task-title';
import language from '../../util/language';

const QuestionnaireQuestions = ({
  questions,
  values,
  onNext,
  onPrevious,
  onQuestionChange,
  onStart,
}) => {
  useEffect(() => {
    if (onStart) {
      onStart();
    }
  }, []);

  const questionNodes = questions.map((q) => (
    <Question
      key={q.id}
      {...q}
      value={values[q.id]}
      order={parseInt(q.order) + 1}
      onChange={onQuestionChange}
    />
  ));

  const previousButton = onPrevious && (
    <Button onClick={onPrevious}>
      {language('questionnaire-button-previous')}
    </Button>
  );

  return (
    <div id="questionnaire">
      <TaskTitle>{language('questionnaire-title')}</TaskTitle>
      {questionNodes}
      <div className="row">
        <div className="small-12 columns text-right">
          {previousButton}
          <Button onClick={onNext}>
            {language('questionnaire-button-next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

QuestionnaireQuestions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape(QUESTION_PROPS)).isRequired,
  values: PropTypes.objectOf(PropTypes.number).isRequired,
  onQuestionChange: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onStart: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default QuestionnaireQuestions;
