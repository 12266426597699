import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, { INSTRUCTIONS, TESTS } from '../constants/sentence-steps';
import {
  SENTENCE_TIME_BETWEEN_WORDS,
  SENTENCE_TIME_SHOWING_WORDS,
} from '../constants/task-parameters';
import Instructions from '../containers/sentence/instructions';
import Tests from '../containers/sentence/tests';

const Sentence = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = (
        <Tests
          timeBetweenWords={SENTENCE_TIME_BETWEEN_WORDS}
          timeShowingWords={SENTENCE_TIME_SHOWING_WORDS}
        />
      );
      break;
  }
  return (
    <DocumentTitle title={title('Sentence Test')}>{content}</DocumentTitle>
  );
};

export default Sentence;

Sentence.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
