import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, { INSTRUCTIONS, TESTS } from '../constants/one-back-steps';
import { ONE_BACK_INTERVAL } from '../constants/task-parameters';
import Instructions from '../containers/one-back/instructions';
import Presenter from '../containers/one-back/presenter';

const OneBack = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = <Presenter timeToHit={ONE_BACK_INTERVAL} />;
      break;
  }
  return (
    <DocumentTitle title={title('One Back Test')}>{content}</DocumentTitle>
  );
};

export default OneBack;

OneBack.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
