import { ADD_REPORT, REMOVE_REPORT } from "../constants/action-types.js";

export const addReport = (reference, report) => ({
  type: ADD_REPORT,
  reference,
  report,
});

export const removeReport = (reference) => ({
  type: REMOVE_REPORT,
  reference,
});
