import { PropTypes } from 'prop-types';
import Preload from 'react-preload';
import EmotionPresentation from './presentation';

const imagesToUrls = (images) => {
  let urls = [];
  for (var image of images) {
    urls.push(require(`../../../images/tasks/emotion/${image}.jpg`));
  }

  return urls;
};

const loadingIndicator = <div>Loading...</div>;

const EmotionPresenter = (props) => (
  <Preload
    loadingIndicator={loadingIndicator}
    images={imagesToUrls(props.tests)}
  >
    {
      <EmotionPresentation
        onStart={props.testIndex == 0 ? props.onStart : null}
        timeToHit={props.timeToHit}
        onTimeout={props.onTimeout}
        onHit={props.onHit}
        key={props.tests[props.testIndex]}
        image={props.tests[props.testIndex]}
      />
    }
  </Preload>
);

export default EmotionPresenter;

EmotionPresenter.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.string).isRequired,
  testIndex: PropTypes.number.isRequired,
  timeToHit: PropTypes.number.isRequired, // in centiseconds
  onTimeout: PropTypes.func.isRequired,
  onHit: PropTypes.func.isRequired,
  onStart: PropTypes.func,
};
