import screenfull from "screenfull";
import { FULLSCREEN_SUPPORTED } from "./browser-support";

const maximize = () => {
  if (FULLSCREEN_SUPPORTED) {
    screenfull.request(document.documentElement);
  }
  window.resizeTo(window.screen.availWidth, window.screen.availHeight);
};

export default maximize;
