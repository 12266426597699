import * as tasks from "../constants/task-types";

// Generator and iterator to convert xml2js $ property for attributes into
// top-level attributes on the parent.
// precondition: expects input to have a property called $.
function* getAttributes(data) {
  yield data.$;
}

const promoteAttributes = (data) => {
  let generator = getAttributes(data);
  let item = generator.next();
  while (!item.done) {
    if (!item.done) {
      let value = item.value;
      for (var prop in value) {
        if (value.hasOwnProperty(prop)) {
          data[prop] = value[prop];
        }
      }

      item = generator.next();
    } else {
      break;
    }
  }

  delete data.$;
  return data;
};

const taskNameToConst = (name) => {
  switch (name) {
    case "questionnaire":
      return tasks.QUESTIONNAIRE_TASK;
    case "word":
      return tasks.WORD_TASK;
    case "time":
      return tasks.TIME_TASK;
    case "emotion_identification":
      return tasks.EMOTION_IDENTIFICATION_TASK;
    case "tapping":
      return tasks.TAPPING_TASK;
    case "one_back":
      return tasks.ONE_BACK_TASK;
    case "stop_or_go":
      return tasks.STOP_OR_GO_TASK;
    case "sentence":
      return tasks.SENTENCE_TASK;
    case "facial_recognition":
      return tasks.FACIAL_RECOGNITION_TASK;
    default:
      throw `Unknown task type: ${name}`;
  }
};

// Deserializers
const deserializeWordTask = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let iterations = data.Word[0].Iterations[0].Iteration;
      let words = {};
      for (var iteration of iterations) {
        let item = {};
        if (iteration.List != null) {
          item.list = [];
          for (var listItem of iteration.List[0].Item) {
            item.list.push(promoteAttributes(listItem).id);
          }
        }

        if (iteration.Pair != null) {
          item.tests = [];
          for (var pairItem of iteration.Pair) {
            let attributes = promoteAttributes(pairItem);
            item.tests.push([attributes.first, attributes.second]);
          }
        }

        words[iteration.$.id] = item;
      }

      fulfill({ word: { iterations: words } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeTaskOrder = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let taskOrder = data.TaskOrder[0].Task;
      var tasks = [];
      for (var task of taskOrder) {
        task = promoteAttributes(task);
        task.task = taskNameToConst(task.id);
        delete task.id;
        tasks.push(task);
      }

      fulfill({ order: tasks });
    } catch (error) {
      reject(error);
    }
  });

const deserializeQuestionnaire = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let questions = data.Questionnaire[0].Question;
      let questionnaire = questions.map((question) => {
        const { response_type: responseType, ...rest } =
          promoteAttributes(question);
        return { responseType, ...rest };
      });

      fulfill({ questionnaire: { questions: questionnaire } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeStopOrGo = (data) =>
  new Promise((fulfill, reject) => {
    try {
      const iterations = data.StopOrGo[0].Iteration.reduce(
        (a, e) => ({
          ...a,
          [e.$.id]: { tests: e.Item.map(promoteAttributes) },
        }),
        {}
      );

      fulfill({ stopOrGo: { iterations } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeOneBack = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let items = data.OneBack[0].Image;
      let tests = [];
      for (var item of items) {
        tests.push(item.$.file);
      }

      fulfill({ oneBack: { tests } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeSentence = (data) =>
  new Promise((fulfill, reject) => {
    try {
      {
        let phrases = data.Sentence[0].Phrase;
        let tests = [];
        for (var phrase of phrases) {
          tests.push(phrase.$);
        }

        fulfill({ sentence: { tests } });
      }
    } catch (error) {
      reject(error);
    }
  });

const deserializeTime = (data) =>
  new Promise((fulfill, reject) => {
    try {
      if (!data.Time) {
        fulfill({ time: { tests: null } });
      }
      let items = data.Time[0].Target;
      let time = [];
      for (var item of items) {
        time.push(parseInt(item.$.seconds));
      }

      fulfill({ time: { tests: time } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeFacialRecognition = (data) =>
  new Promise((fulfill, reject) => {
    try {
      const tests = data.FacialRecognition[0].Pair.map((i) => [
        i.$.first,
        i.$.second,
      ]);

      fulfill({ facialRecognition: { tests } });
    } catch (error) {
      reject(error);
    }
  });

const deserializeEmotionIdentification = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let iterations = data.EmotionIdentification[0].Iteration;
      let emotions = {};
      for (var iteration of iterations) {
        let item = {
          id: iteration.$.id,
        };
        item.tests = [];
        for (var listItem of iteration.List[0].Item) {
          item.tests.push(promoteAttributes(listItem).id);
        }

        emotions[item.id] = { tests: item.tests };
      }

      fulfill({ emotions: { iterations: emotions } });
    } catch (error) {
      reject(error);
    }
  });

const composeTestSession = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let order = deserializeTaskOrder(data);
      let word = deserializeWordTask(data);
      let questionnaire = deserializeQuestionnaire(data);
      let stopOrGo = deserializeStopOrGo(data);
      let oneBack = deserializeOneBack(data);
      let sentence = deserializeSentence(data);
      let time = deserializeTime(data);
      let facialRecognition = deserializeFacialRecognition(data);
      let emotionIdentification = deserializeEmotionIdentification(data);
      let promises = [
        order,
        word,
        questionnaire,
        stopOrGo,
        oneBack,
        sentence,
        time,
        facialRecognition,
        emotionIdentification,
      ];
      fulfill(promises);
    } catch (error) {
      reject(error);
    }
  });

export const deserializeTestSession = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let testSession = promoteAttributes(data.Session);
      let session = {
        number: testSession.number,
        concussed: testSession.concussed,
        tasks: {},
      };
      composeTestSession(testSession).then((promises) =>
        Promise.all(promises).then((data) => {
          for (var item of data) {
            for (var att in item) {
              session.tasks[att] = item[att];
            }
          }

          fulfill(session);
        })
      );
    } catch (error) {
      reject(error);
    }
  });

export const deserializeAuthorization = (data) =>
  new Promise((fulfill, reject) => {
    try {
      let generator = getAttributes(data.auth);
      let item = generator.next();
      fulfill(item.value);
    } catch (error) {
      reject(error);
    }
  });
