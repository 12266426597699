import { NEXT_STEP } from "../../constants/action-types";
import { firstStep, nextStep } from "../../util/task-step";

export default (state, { type }, task, iterationParams) => {
  if (state == undefined) {
    return firstStep(task, iterationParams);
  }
  switch (type) {
    case NEXT_STEP:
      return nextStep(task, state, iterationParams);
    default:
      return state;
  }
};
