import { connect } from "react-redux";
import { getCurrentTaskParams } from "../../selectors/tasks";
import TestsComponent from "../../components/stop-or-go/tests";
import {
  nextTask,
  nextTest,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
} from "../../actions/index";

const stateToProps = (state) => {
  const {
    tasks: {
      stopOrGo: { iterations },
    },
  } = state;
  const { testIndex, iteration } = getCurrentTaskParams(state);
  const tests = iterations[iteration].tests;
  return { iteration, tests, testIndex };
};

const dispatchProps = {
  nextTest,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
};

const mergeProps = (
  stateProps,
  {
    nextTest: boundNextTest,
    setShowTaskCompletion: boundSetShowTaskCompletion,
    markTaskResultsStart: boundMarkTaskResultsStart,
    markTaskResultsEnd: boundMarkTaskResultsEnd,
    addTaskResultDetails: boundAddTaskResultDetails,
  },
  otherProps
) => {
  const { testIndex, tests } = stateProps;
  const lastTest = testIndex == tests.length - 1;
  const next = () =>
    setTimeout(() => {
      if (lastTest) {
        boundMarkTaskResultsEnd();
        boundSetShowTaskCompletion(true, nextTask());
      } else {
        boundNextTest();
      }
    }, 1000);
  const handleStart = () => boundMarkTaskResultsStart();
  const handleHit = (reactionTime, tapDuration) => {
    boundAddTaskResultDetails({ response: "hit", reactionTime, tapDuration });
    next();
  };
  const handleTimeout = () => {
    boundAddTaskResultDetails({
      response: "not-hit",
      reactionTime: 0,
      tapDuration: 0,
    });
    next();
  };
  return {
    ...stateProps,
    ...otherProps,
    onStart: handleStart,
    onTimeout: handleTimeout,
    onHit: handleHit,
    timeToHit: 1.1,
  };
};

const Tests = connect(stateToProps, dispatchProps, mergeProps)(TestsComponent);

export default Tests;
