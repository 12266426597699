import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, { INSTRUCTIONS, TESTS } from '../constants/stop-or-go-steps';
import { STOP_OR_GO_INTERVAL } from '../constants/task-parameters';
import Tests from '../containers/stop-or-go/tests';
import Instructions from '../containers/stop-or-go/instructions';

const StopOrGo = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = <Tests timeToHit={STOP_OR_GO_INTERVAL} />;
      break;
  }
  return (
    <DocumentTitle title={title(`Stop or Go Test`)}>{content}</DocumentTitle>
  );
};

export default StopOrGo;

StopOrGo.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
