import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

class Pair extends XlntTestComponent {
  // word should be "first" or "second"
  // keyUpTime is passed as an argument simply to reduce the time
  // between the key press and the recording of the time
  triggerHit(keyUpTime, response) {
    this.stop();
    const tapDuration = (keyUpTime - this.keyDownTime) / 1000;
    const reactionTime = (this.keyDownTime - this.startedAt) / 1000;
    this.props.onHit(response, reactionTime, tapDuration);
  }

  start() {
    SendEchoSignal('H');
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 10;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return (
      [LEFT_ARROW, RIGHT_ARROW].includes(event.keyCode) && !this.ignoreKeys
    );
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now, event.keyCode == LEFT_ARROW ? 'first' : 'second');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.word1 != nextProps.word1 ||
      this.props.word2 != nextProps.word2
    ) {
      this.needsRestart = true;
    }
  }

  render() {
    if (!this.state.active) {
      return null;
    }
    return (
      <div id="word-pair">
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="pair">
          <div className="word">
            {language('word-list-' + this.props.word1)}
          </div>
          <div className="word">
            {language('word-list-' + this.props.word2)}
          </div>
        </div>
      </div>
    );
  }
}

export default Pair;

Pair.propTypes = {
  word1: PropTypes.string.isRequired,
  word2: PropTypes.string.isRequired,
  timeToHit: PropTypes.number.isRequired, // in centiseconds
  onTimeout: PropTypes.func.isRequired,
  onHit: PropTypes.func.isRequired,
  onStart: PropTypes.func,
};
