import { connect } from "react-redux";
import LoginComponent from "../components/login";
import { setUsername, setPassword, login, setUserToken } from "../actions/index";
import maximize from "../util/maximize";

const mapStateToProps = ({ authentication }) => ({ ...authentication });
const mapDispatchToProps = (dispatch) => ({
  onChangeUsername: (username) => {
    dispatch(setUsername(username));
  },
  onChangePassword: (password) => {
    dispatch(setPassword(password));
  },
  onLoginWithUserToken: (userToken) => {
    dispatch(setUserToken(userToken));
    dispatch(login());
  },
  onSubmit: () => {
    maximize();
    dispatch(login());
  },
});

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);

export default Login;
