import { deserializeTestSession } from "../util/deserializer";
import { parseXml } from "../util/xml";
import { SET_TEST_SESSION } from "../constants/action-types.js";
import { nextTask } from "./tasks";

export const setTestSession = (data) => (dispatch) => {
  dispatch({ type: SET_TEST_SESSION, data: data });
  // start on the first task
  dispatch(nextTask());
};

export const setTestSessionFromXML = (xml) => (dispatch) => {
  parseXml(xml)
    .then(deserializeTestSession)
    .then((data) => dispatch(setTestSession(data)));
};
