import { connect } from "react-redux";
import { getCurrentTaskParams } from "../../selectors/tasks";
import PresentationComponent from "../../components/word/presentation";
import { nextStep } from "../../actions/index";

const stateToProps = (state) => {
  const iterations = state.tasks.word.iterations;
  const currentIterationIndex = getCurrentTaskParams(state).iteration;
  return { list: iterations[currentIterationIndex].list };
};

const Presentation = connect(stateToProps, { onNext: nextStep })(
  PresentationComponent
);

export default Presentation;
