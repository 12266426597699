import { connect } from "react-redux";
import { getCurrentTaskParams } from "../../selectors/tasks";
import { nextStep } from "../../actions/index";
import WordInstructionsComponent from "../../components/word/instructions";
import maximize from "../../util/maximize";

const stateToProps = (state) => {
  const iteration = getCurrentTaskParams(state).iteration;
  const hasList = state.tasks.word.iterations[iteration].list !== undefined;
  return { iteration, hasList };
};

const WordInstructions = connect(stateToProps, (dispatch) => ({
  onNext: () => {
    maximize();
    dispatch(nextStep());
  },
}))(WordInstructionsComponent);

export default WordInstructions;
