import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';

const TappingInstructions = (props) => {
  const steps = [
    [
      language('tapping-instructions-message-1'),
      <img src={require('../../../images/instructions/TapTap.png')} />,
    ],
    [
      <span
        dangerouslySetInnerHTML={{
          __html: language('tapping-instructions-message-2'),
        }}
      ></span>,
      null,
    ],
    [
      language('tapping-instructions-message-3'),
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
    [
      language('tapping-instructions-message-4'),
      ,
      <div id="tapping-animation">
        <img src={require('../../../images/instructions/3.png')} />
        <img src={require('../../../images/instructions/2.png')} />
        <img src={require('../../../images/instructions/1.png')} />
      </div>,
    ],
  ];

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/zo4RjV0I20A?si=3VCyn8zM40Xemmcq"
      title="Tapping Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={32} onKey={props.onNext}>
      <Instructions
        title={language('tapping-title')}
        steps={steps}
        id="tapping-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default TappingInstructions;

TappingInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
