import { PropTypes } from 'prop-types';
import {
  YES_NO_QUESTION,
  ZERO_TO_FOUR_QUESTION,
} from '../../constants/question-types';
import YesNoQuestion from './yes-no-question';
import ZeroToFourQuestion from './zero-to-four-question';
import language from '../../util/language';

export const QUESTION_PROPS = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  responseType: PropTypes.oneOf([YES_NO_QUESTION, ZERO_TO_FOUR_QUESTION])
    .isRequired,
};

const Question = ({ responseType, text, id, value, order, onChange }) => {
  const handleChange = (response) => onChange(id, response);
  let question;
  switch (responseType) {
    case YES_NO_QUESTION:
      question = (
        <YesNoQuestion name={id} onChange={handleChange} value={value} />
      );
      break;
    case ZERO_TO_FOUR_QUESTION:
      question = (
        <ZeroToFourQuestion name={id} onChange={handleChange} value={value} />
      );
      break;
  }
  return (
    <div className="row question">
      <div className="small-6 columns">
        {language('questionnaire-question-' + order)}
      </div>
      <div className="small-6 columns">{question}</div>
    </div>
  );
};

Question.propTypes = {
  ...QUESTION_PROPS,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

export default Question;
