export const RESTART = "RESTART";
export const SET_USERNAME = "SET_USERNAME";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const LOGIN = "LOGIN";
export const SET_TRIAL_VERSION = "SET_TRIAL_VERSION";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_AUTHENTICATING = "SET_AUTHENTICATING";
export const SET_LANDING_SHOWN = "SET_LANDING_SHOWN";
export const SET_PRE_LOGIN_SHOWN = "SET_PRE_LOGIN_SHOWN";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_COG_TEST_START = "SET_COG_TEST_START";
export const SET_COG_TEST_END = "SET_COG_TEST_END";
export const SET_SUBMIT_REPORT_ERROR = "SET_SUBMIT_REPORT_ERROR";
export const SET_SUBMIT_REPORT_COMPLETE = "SET_SUBMIT_REPORT_COMPLETE";

// test session
export const SET_RETRIEVING_TEST_SESSION = "SET_RETRIEVING_TEST_SESSION";
export const SET_TEST_SESSION = "SET_TEST_SESSION";

// tasks
export const NEXT_TASK = "NEXT_TASK";
export const NEXT_STEP = "NEXT_STEP";
export const NEXT_TEST = "NEXT_TEST";
export const SET_SHOW_TASK_COMPLETION = "SET_SHOW_TASK_COMPLETION";
export const SET_TASK_RESULTS_START = "SET_TASK_RESULTS_START";
export const SET_TASK_RESULTS_END = "SET_TASK_RESULTS_END";
export const ADD_TASK_RESULT_DETAILS = "ADD_TASK_RESULT_DETAILS";
export const SET_TASK_RESULT_DETAILS = "SET_TASK_RESULT_DETAILS";

// questionnnaire
export const NEXT_QUESTIONNAIRE_PAGE = "NEXT_QUESTIONNAIRE_PAGE";
export const PREVIOUS_QUESTIONNAIRE_PAGE = "PREVIOUS_QUESTIONNAIRE_PAGE";
export const SET_QUESTIONNAIRE_INSTRUCTIONS_SHOWN =
  "SET_QUESTIONNAIRE_INSTRUCTIONS_SHOWN";

// reports
export const ADD_REPORT = "ADD_REPORT";
export const REMOVE_REPORT = "REMOVE_REPORT";
