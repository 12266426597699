import language from '../../util/language';

const YesNoQuestion = ({ name, value, onChange }) => {
  const handleChange = (value) => (e) => {
    if (e.target.checked) {
      onChange(value);
    }
  };

  return (
    <div className="yes-no-question-options">
      <label>
        <input
          type="radio"
          name={name}
          value={1}
          onChange={handleChange(1)}
          checked={value == 1}
        />{' '}
        {language('questionnaire-button-yes')}
      </label>
      <label>
        <input
          type="radio"
          name={name}
          value={0}
          onChange={handleChange(0)}
          checked={value == 0}
        />{' '}
        {language('questionnaire-button-no')}
      </label>
    </div>
  );
};

export default YesNoQuestion;
