import {
  SET_USERNAME,
  SET_PASSWORD,
  SET_AUTHENTICATED,
  SET_AUTHENTICATING,
  SET_USER_TOKEN
} from "../constants/action-types";

const authentication = (
  state = {
    username: null,
    password: null,
    authenticated: false,
    authenticating: false,
    userToken: null
  },
  { type, username, password, authenticated, authenticating, userToken }
) => {
  switch (type) {
    case SET_USERNAME:
      return { ...state, username };
    case SET_PASSWORD:
      return { ...state, password };
    case SET_AUTHENTICATED:
      return { ...state, authenticated };
    case SET_AUTHENTICATING:
      return { ...state, authenticating };
    case SET_USER_TOKEN:
      return { ...state, userToken }
    default:
      return state;
  }
};

export default authentication;
