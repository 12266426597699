import { connect } from "react-redux";
import { getCurrentTaskParams } from "../../selectors/tasks";
import Pair from "../../components/word/pair";
import {
  nextTask,
  nextTest,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
  setShowTaskCompletion,
} from "../../actions/index";

const stateToProps = (state) => {
  const {
    tasks: {
      word: { iterations },
    },
  } = state;
  const { testIndex, iteration } = getCurrentTaskParams(state);
  const tests = iterations[iteration].tests;
  const test = tests[testIndex];
  return {
    iteration,
    firstTest: testIndex == 0,
    lastTest: testIndex == tests.length - 1,
    word1: test[0],
    word2: test[1],
    timeToHit: Math.floor(1.9 * 60),
  };
};

const mergeProps = (
  { firstTest, lastTest, iteration, ...stateProps },
  { dispatch },
  otherProps
) => {
  const next = () =>
    setTimeout(() => {
      if (lastTest) {
        dispatch(markTaskResultsEnd());
        dispatch(setShowTaskCompletion(true, nextTask()));
      } else {
        dispatch(nextTest());
      }
    }, 1000);
  const handleStart = firstTest ? () => dispatch(markTaskResultsStart()) : null;
  return {
    ...stateProps,
    ...otherProps,
    onStart: handleStart,
    onTimeout: () => {
      dispatch(
        addTaskResultDetails({ response: "", reactionTime: 0, tapDuration: 0 })
      );
      next();
    },
    onHit: (response, reactionTime, tapDuration) => {
      dispatch(addTaskResultDetails({ response, reactionTime, tapDuration }));
      next();
    },
  };
};

const Pairs = connect(
  stateToProps,
  (dispatch) => ({ dispatch }),
  mergeProps
)(Pair);

export default Pairs;
