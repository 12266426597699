import * as tasks from "../constants/task-types";

const formatDate = (mseconds) => {
  const date = new Date(mseconds);
  const pad = (n) => (n < 10 ? `0${n}` : n);
  const d = [
    date.getUTCFullYear(),
    pad(date.getUTCMonth() + 1),
    pad(date.getUTCDate()),
  ].join("-");
  const t = [
    pad(date.getUTCHours()),
    pad(date.getUTCMinutes()),
    pad(date.getUTCSeconds()),
  ].join(":");
  return `${d} ${t} UTC`;
};

function* entries(obj) {
  for (let key of Object.keys(obj)) {
    yield [key, obj[key]];
  }
}

function* contentGenerator(content) {
  for (let item of content) {
    let Item = {
      $: {},
    };

    Object.assign(Item.$, item);

    yield Item;
  }
}

const itemizer = (items, arrayName) => {
  let Item = { $: {}, [arrayName]: [] };
  if (items.startTime) {
    let formattedStart = formatDate(items.startTime);
    Item.$.test_start = formattedStart;
  }

  if (items.endTime) {
    let formattedEnd = formatDate(items.endTime);
    Item.$.test_end = formattedEnd;
  }

  if (items.details) {
    let content = contentGenerator(items.details);
    for (let item of content) {
      Item[arrayName].push(item);
    }
  }

  return Item;
};

function* iterationGenerator(
  iterations,
  attribute,
  contentName,
  subIterationName = null
) {
  let items = entries(iterations);
  for (let iteration of items) {
    let Iteration = { $: {} };
    if (iteration[1].startTime) {
      Iteration.$.test_start = formatDate(iteration[1].startTime);
    }

    if (iteration[1].endTime) {
      Iteration.$.test_end = formatDate(iteration[1].endTime);
    }

    Iteration.$[attribute] = iteration[0];

    let content = contentGenerator(iteration[1].details);
    if (subIterationName) {
      Iteration[subIterationName] = {
        [contentName]: [],
      };
      for (let item of content) {
        Iteration[subIterationName][contentName].push(item);
      }
    } else {
      Iteration[contentName] = [];
      for (let item of content) {
        Iteration[contentName].push(item);
      }
    }

    yield Iteration;
  }
}

const iterator = (iterations) => {
  let Item = { Iterations: { Iteration: [] } };
  for (let iteration of iterations) {
    Item.Iterations.Iteration.push(iteration);
  }

  return Item;
};

const serializeSentence = (sentence) =>
  new Promise((fulfill, reject) => {
    try {
      let Sentence = itemizer(sentence, "Phrase");
      fulfill({ Sentence: Sentence });
    } catch (error) {
      reject(error);
    }
  });

const serializeStopOrGo = (stopOrGo) =>
  new Promise((fulfill, reject) => {
    try {
      let iterations = iterationGenerator(stopOrGo, "id", "Item");
      fulfill({ StopOrGo: iterator(iterations) });
    } catch (error) {
      reject(error);
    }
  });

const serializeOneBack = (oneBack) =>
  new Promise((fulfill, reject) => {
    try {
      let OneBack = itemizer(oneBack, "Item");
      fulfill({ OneBack: OneBack });
    } catch (error) {
      reject(error);
    }
  });

const serializeTapping = (tapping) =>
  new Promise((fulfill, reject) => {
    try {
      let Tapping = itemizer(tapping, "Tap");
      fulfill({ Tapping: Tapping });
    } catch (error) {
      reject(error);
    }
  });

const serializeTime = (time) =>
  new Promise((fulfill, reject) => {
    try {
      let Time = itemizer(time, "Target");
      fulfill({ Time: Time });
    } catch (error) {
      reject(error);
    }
  });

const serializeFacialRecognition = (facialRecognition) =>
  new Promise((fulfill, reject) => {
    try {
      let FacialRecognition = itemizer(facialRecognition, "Pair");
      fulfill({ FacialRecognition: FacialRecognition });
    } catch (error) {
      reject(error);
    }
  });

const serializeEmotionIdentification = (emotionIdentification) =>
  new Promise((fulfill, reject) => {
    try {
      let iterations = iterationGenerator(
        emotionIdentification,
        "emotion",
        "Photo",
        "Photos"
      );

      fulfill({ EmotionIdentification: iterator(iterations) });
    } catch (error) {
      reject(error);
    }
  });

const serializeWord = (word) =>
  new Promise((fulfill, reject) => {
    try {
      let iterations = iterationGenerator(word, "number", "Pair");
      fulfill({ Word: iterator(iterations) });
    } catch (error) {
      reject(error);
    }
  });

const serializeQuestionnaire = (questionnaire) =>
  new Promise((fulfill, reject) => {
    try {
      let Questionnaire = itemizer(questionnaire, "Question");

      fulfill({ Questionnaire: Questionnaire });
    } catch (error) {
      reject(error);
    }
  });

const composeReport = (results) =>
  new Promise((fulfill, reject) => {
    try {
      let promises = [];
      if (results.questionnaire) {
        let questionnaire = serializeQuestionnaire(results.questionnaire);
        promises.push(questionnaire);
      }

      if (results.word) {
        let word = serializeWord(results.word);
        promises.push(word);
      }

      if (results.emotions) {
        let emotionIdentification = serializeEmotionIdentification(
          results.emotions
        );
        promises.push(emotionIdentification);
      }

      if (results.time) {
        let time = serializeTime(results.time);
        promises.push(time);
      }

      if (results.tapping) {
        let tapping = serializeTapping(results.tapping);
        promises.push(tapping);
      }

      if (results.sentence) {
        let sentence = serializeSentence(results.sentence);
        promises.push(sentence);
      }

      if (results.stopOrGo) {
        let stopOrGo = serializeStopOrGo(results.stopOrGo);
        promises.push(stopOrGo);
      }

      if (results.oneBack) {
        let oneBack = serializeOneBack(results.oneBack);
        promises.push(oneBack);
      }

      if (results.facialRecognition) {
        let facialRecognition = serializeFacialRecognition(
          results.facialRecognition
        );
        promises.push(facialRecognition);
      }

      fulfill(promises);
    } catch (error) {
      reject(error);
    }
  });

export const serializeReport = ({
  authentication: { username },
  sessionNumber,
  tasks: { results = {} },
}) =>
  new Promise((fulfill, reject) => {
    try {
      let doc = {
        Responses: {
          $: {
            session: sessionNumber,
            version: "Version: 3.0",
            build: "Build: 1",
            user: username,
          },
        },
      };

      if (results.cogTestStart) {
        let formattedStart = formatDate(results.cogTestStart);
        doc.Responses.$.test_start = formattedStart;
      }

      if (results.cogTestEnd) {
        let formattedEnd = formatDate(results.cogTestEnd);
        doc.Responses.$.test_end = formattedEnd;
      }

      composeReport(results).then((promises) =>
        Promise.all(promises)
          .then((data) => {
            for (let item of data) {
              Object.assign(doc.Responses, item);
            }

            return doc;
          })
          .then((doc) => {
            fulfill(doc);
          })
      );
    } catch (error) {
      reject(error);
    }
  });
