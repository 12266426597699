import * as types from "../constants/action-types";

export const markCogTestStart = () => (dispatch) =>
  dispatch({
    type: types.SET_COG_TEST_START,
    time: Date.now(),
  });

export const markCogTestEnd = () => (dispatch) =>
  dispatch({
    type: types.SET_COG_TEST_END,
    time: Date.now(),
  });
