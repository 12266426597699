import currentTask from "./current-task";
import authentication from "./authentication";
import tasks from "./tasks";
import landingShown from "./landing-shown";
import preLoginShown from "./pre-login-shown";
import trialVersion from "./trial-version";
import reports from "./reports";
import {
  SET_TEST_SESSION,
  SET_LANGUAGE,
  RESTART,
  SET_RETRIEVING_TEST_SESSION,
} from "../constants/action-types.js";
import { currentTask as currentTaskOrderData } from "../util/task";

export default (realState, realAction) => {
  let state = realState;
  let action = realAction;
  if (action.type === RESTART) {
    state = undefined;
    action = {};
  }
  let sessionNumber, tasksState;

  if (action.type == SET_LANGUAGE) {
    window.language_strings = action.data;
  }

  if (action.type == SET_TEST_SESSION) {
    sessionNumber = action.data.number;
    tasksState = action.data.tasks;
  } else {
    const ct = (state && currentTaskOrderData(state)) || {};
    tasksState = tasks(state && state.tasks, action, ct.task, ct.iteration);
    sessionNumber = (state && state.sessionNumber) || null;
  }
  const oldRetrievingTestSession = state ? state.retrievingTestSession : false;
  const retrievingTestSession =
    action.type === SET_RETRIEVING_TEST_SESSION
      ? realAction.retrievingTestSession
      : oldRetrievingTestSession;
  return {
    retrievingTestSession,
    sessionNumber,
    authentication: authentication(state && state.authentication, action),
    tasks: tasksState,
    preLoginShown: preLoginShown(state && state.preLoginShown, action),
    landingShown: landingShown(state && state.landingShown, action),
    trialVersion: trialVersion(state && state.trialVersion, action),
    // the currentTask needs to know about task order
    currentTask: currentTask(state && state.currentTask, action, tasksState),
    reports: reports(state && state.reports, action),
  };
};
