import { combineReducers } from "redux";
import order from "./tasks/order";
import results from "./tasks/results";

const unimplemented = (state = null, action) => state;

export default (state, action, taskType, iteration) =>
  combineReducers({
    order,
    results: (state, action) => results(state, action, taskType, iteration),
    questionnaire: unimplemented,
    word: unimplemented,
    stopOrGo: unimplemented,
    oneBack: unimplemented,
    sentence: unimplemented,
    time: unimplemented,
    facialRecognition: unimplemented,
    emotions: unimplemented,
  })(state, action);
