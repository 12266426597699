import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import language from '../../util/language';

const SPACE_BAR = 32;

class TappingTest extends XlntTestComponent {
  constructor(props) {
    super(props);
    this.previousReactionTime = 0;
    this.hits = [];
    this.duration = 3000;
    this.state = { active: false, countdown: 3, timeLeft: this.duration };
  }

  triggerHit(keyUpTime) {
    const tapDuration = (keyUpTime - this.keyDownTime) / 1000;
    const reactionTimeMiddleWare = (this.keyDownTime - this.startedAt) / 1000;
    const reactionTime = reactionTimeMiddleWare - this.previousReactionTime;
    this.previousReactionTime = reactionTimeMiddleWare;

    this.hits.push({ tapDuration, reactionTime });
  }

  stop() {
    this.setState({ active: false });
    this.ignoreKeys = true;
    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
    this.props.onEnd(this.hits);
  }

  start() {
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 10;
      const timeLeft = this.duration - timeSpent;
      if (this._isMounted) {
        this.setState({ timeLeft });
      }
      return timeLeft;
    };

    updateTimeLeft();

    if (this._isMounted) {
      this.setState({ active: true });
    }

    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft <= 0) {
        this.stop();
      }
    };

    this.intervalID = setInterval(onInterval, 10);
  }

  isRelevantKey(event) {
    return event.keyCode == SPACE_BAR && !this.ignoreKeys;
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now);
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    const countdown = () => {
      let interval = setInterval(
        () => {
          if (this.state.countdown > 1) {
            this.setState({ countdown: this.state.countdown - 1 });
          } else {
            clearInterval(interval);
            this.intervalID = null;
            // this.setState({ active: true });
            this.start();
          }
        },

        1000
      );
    };

    countdown();

    window.addEventListener('keydown', this);
    window.addEventListener('keyup', this);
  }

  componentDidUpdate() {}

  render() {
    return this.state.active ? (
      <div id="tapping">
        <CustomProgressBar
          completed={parseInt(100 * (this.state.timeLeft / this.duration))}
          height="30px"
          speed="0.01"
        />
      </div>
    ) : this.state.countdown > 0 && this.state.timeLeft == this.duration ? (
      <div id="intermission" className="tapping">
        <span className="intermission">
          {language('tapping-instructions-start-task-message-1').replace(
            '[[time]]',
            this.state.countdown
          )}
        </span>
      </div>
    ) : null;
  }
}

export default TappingTest;

TappingTest.propTypes = {
  // called when the countdown starts, no arguments
  onStart: PropTypes.func,

  // called when the test ends
  // the only argument is an array of hashes representing each spacebar tap
  // the hash entries should be reactionTime, a float, and tapDuration, another float
  onEnd: PropTypes.func.isRequired,
};
