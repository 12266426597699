import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

// presentation of words to be used in the test
// they're presented one at a time
const Presentation = ({ list, onNext }) => {
  const [listIndex, setListIndex] = useState(0);
  const [showWord, setShowWord] = useState(true);
  const [word, setWord] = useState(list[0]);

  useEffect(() => {
    const delay = 1000;

    const hideWord = (nextIndex) => {
      setShowWord(false);
      setListIndex(nextIndex + 1);
      setWord(list[nextIndex + 1]);
      setTimeout(
        () =>
          nextIndex + 1 < list.length ? showWord(nextIndex + 1) : onNext(),
        delay
      );
    };

    const showWord = (index) => {
      setShowWord(true);
      setTimeout(() => hideWord(index), delay);
      SendEchoSignal('G');
    };

    const initialTimeoutId = setTimeout(() => hideWord(listIndex), delay);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  }, [list, onNext]);

  return (
    <div id="word-presentation">
      {showWord ? language('word-list-' + word) : null}
    </div>
  );
};

Presentation.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Presentation;
