import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';

const QuestionnaireInstructions = ({ onNext }) => {
  const steps = [
    [language('questionnaire-instructions-message-1'), null],
    null,
    [
      language('questionnaire-instructions-message-2'),
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
  ];
  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/TCoqac4hxiQ?si=9z7fDyLkqWgp4E68"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );
  return (
    <KeyListener keyCode={32} onKey={onNext}>
      <Instructions
        title={language('questionnaire-title')}
        steps={steps}
        video={video}
      />
    </KeyListener>
  );
};

export default QuestionnaireInstructions;

QuestionnaireInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
