import * as actionTypes from "../constants/action-types";
import { markCogTestEnd } from "./cog-test";
import { onLastTask } from "../selectors/tasks";
import { doSubmitReport } from "../util/api";
import { addReport, removeReport } from "./reports";
import { generateReport, reportReference } from "../util/report";

const Sentry = window.Sentry;

export const setSubmitReportError = (message, detail = null) => ({
  type: actionTypes.SET_SUBMIT_REPORT_ERROR,
  message,
  detail,
});

export const setSubmitReportComplete = (complete = true) => ({
  type: actionTypes.SET_SUBMIT_REPORT_COMPLETE,
  complete,
});

export const submitReport = () => (dispatch, getState) => {
  const state = getState();
  const {
    authentication: { username },
    sessionNumber,
  } = state;
  const reference = reportReference(username, sessionNumber);
  dispatch(setSubmitReportError(null));

  const sendReport = (report, attempts = 0) =>
    doSubmitReport(username, reference, report)
      .then(() => {
        dispatch(setSubmitReportComplete());
        dispatch(setSubmitReportError(null));
      })
      .then(() => dispatch(removeReport(reference)))
      .catch((e) => {
        if (attempts < 7) {
          Sentry.captureException(e);
          const backoffTime = Math.pow(2, attempts) * 1000;
          const nextAttempt = attempts + 1;
          Sentry.captureMessage(`Submit failed after ${nextAttempt} attempts.`);
          Sentry.captureMessage(`Exception message: ${e.toString()}.`);
          setTimeout(() => sendReport(report, nextAttempt), backoffTime);
        } else {
          Sentry.captureException(e);
          const nextAttempt = attempts + 1;
          Sentry.captureMessage(`Submit failed after ${nextAttempt} attempts.`);
          Sentry.captureMessage(`Exception message: ${e.toString()}.`);
          dispatch(
            setSubmitReportError(
              "There was an error sending the report: SERVER_ERROR",
              [e, ...(e.stack ? [e.stack] : [])].join("\n")
            )
          );
        }
      });

  const setOfflineError = () => {
    Sentry.captureException(new Error('Error: User browser is offline!'));
    dispatch(
      setSubmitReportError(
        "There was an error sending the report: REPORT_ERROR",
        "There's no network connection...\n\nReport hasn't been sent"
      )
    );
  }

  generateReport(state).then((report) => {
    dispatch(addReport(reference, report));
    if (navigator.onLine) {
      sendReport(report);
    } else {
      setOfflineError();
    }
  });
};

export const nextTask = () => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: actionTypes.NEXT_TASK });
  if (onLastTask(state)) {
    dispatch(markCogTestEnd());
    dispatch(submitReport());
  }
};
export const nextStep = () => ({ type: actionTypes.NEXT_STEP });
export const nextTest = () => ({ type: actionTypes.NEXT_TEST });

export const markTaskResultsStart = () => (dispatch) =>
  dispatch({
    type: actionTypes.SET_TASK_RESULTS_START,
    time: Date.now(),
  });

export const markTaskResultsEnd = () => (dispatch) =>
  dispatch({
    type: actionTypes.SET_TASK_RESULTS_END,
    time: Date.now(),
  });

// argument should be an object representing an individual test
// or question in the questionnaire
export const addTaskResultDetails = (details) => ({
  type: actionTypes.ADD_TASK_RESULT_DETAILS,
  details,
});

export const setTaskResultDetails = (details, equalityFunc) => ({
  type: actionTypes.SET_TASK_RESULT_DETAILS,
  details,
  equalityFunc,
});
