import * as tasks from "../constants/task-types";

// the hash values are the names of the keys in the tasks hash in the store
// TAPPING_TASK doesn't have data, but the key is used for results
export const TASK_DATA_KEY = {
  [tasks.QUESTIONNAIRE_TASK]: "questionnaire",
  [tasks.WORD_TASK]: "word",
  [tasks.TIME_TASK]: "time",
  [tasks.EMOTION_IDENTIFICATION_TASK]: "emotions",
  [tasks.TAPPING_TASK]: "tapping",
  [tasks.ONE_BACK_TASK]: "oneBack",
  [tasks.STOP_OR_GO_TASK]: "stopOrGo",
  [tasks.SENTENCE_TASK]: "sentence",
  [tasks.FACIAL_RECOGNITION_TASK]: "facialRecognition",
};

// given the entire state tree, returns { task, iteration }
export const currentTask = ({
  tasks: { order },
  currentTask: { taskOrderIndex },
}) => (order || [])[taskOrderIndex] || {};

export const iterationParams = (task, iteration, tasksSessionParams) => {
  const taskSessionParams = tasksSessionParams[TASK_DATA_KEY[task]];
  return iteration && iteration !== ""
    ? taskSessionParams.iterations[iteration]
    : taskSessionParams;
};
