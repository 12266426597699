import { connect } from "react-redux";
import TestsComponent from "../../components/time/tests";
import {
  nextTask,
  nextTest,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
} from "../../actions/index";

const stateToProps = ({
  currentTask: { testIndex },
  tasks: {
    time: { tests },
  },
}) => ({
  tests,
  testIndex,
});

const dispatchProps = {
  nextTest,
  addTaskResultDetails,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
};

const mergeProps = (
  stateProps,
  {
    nextTest: boundNextTest,
    setShowTaskCompletion: boundSetShowTaskCompletion,
    addTaskResultDetails: boundAddTaskResultDetails,
    markTaskResultsStart: boundMarkTaskResultsStart,
    markTaskResultsEnd: boundMarkTaskResultsEnd,
  },
  otherProps
) => {
  const { testIndex, tests } = stateProps;
  const lastTest = testIndex == tests.length - 1;
  const next = () =>
    setTimeout(() => {
      if (lastTest) {
        boundMarkTaskResultsEnd();
        boundSetShowTaskCompletion(true, nextTask());
      } else {
        boundNextTest();
      }
    }, 20);
  const handleStart = () => boundMarkTaskResultsStart();
  const handleTimeout = (id) => {
    boundAddTaskResultDetails({ id, response: "not-hit" });
    next();
  };
  return {
    ...stateProps,
    ...otherProps,
    timeToHit: 45,
    onStart: handleStart,
    onHit: (response) => {
      boundAddTaskResultDetails({ response });
      next();
    },
    onTimeout: handleTimeout,
  };
};

const Tests = connect(stateToProps, dispatchProps, mergeProps)(TestsComponent);

export default Tests;
