import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import PreLogin from '../containers/pre-login';
import Login from '../containers/login';
import LandingPage from '../containers/landing-page';
import Questionnaire from '../containers/questionnaire';
import Word from '../containers/word';
import Emotion from '../containers/emotion';
import TaskCompletionMessage from './task-completion-message';
import Time from '../containers/time';
import Tapping from '../containers/tapping';
import StopOrGo from '../containers/stop-or-go';
import OneBack from '../containers/one-back';
import Sentence from '../containers/sentence';
import FacialRecognition from '../containers/facial-recognition';
import SendReport from '../containers/send-report';
import * as tasks from '../constants/task-types';
import title from '../util/title';

const Contents = ({
  task,
  showPreLogin,
  showLogin,
  showLanding,
  showFinished,
  showTaskCompletion,
}) => {
  if (showPreLogin) {
    return <PreLogin />;
  } else if (showLogin) {
    return <Login />;
  } else if (showLanding) {
    return <LandingPage />;
  } else if (showFinished) {
    return <SendReport />;
  } else if (showTaskCompletion) {
    return <TaskCompletionMessage />;
  } else {
    switch (task) {
      case tasks.WORD_TASK:
        return <Word />;
      case tasks.QUESTIONNAIRE_TASK:
        return <Questionnaire />;
      case tasks.EMOTION_IDENTIFICATION_TASK:
        return <Emotion />;
      case tasks.TIME_TASK:
        return <Time />;
      case tasks.TAPPING_TASK:
        return <Tapping />;
      case tasks.STOP_OR_GO_TASK:
        return <StopOrGo />;
      case tasks.ONE_BACK_TASK:
        return <OneBack />;
      case tasks.SENTENCE_TASK:
        return <Sentence />;
      case tasks.FACIAL_RECOGNITION_TASK:
        return <FacialRecognition />;
      default:
        throw Error(`Unimplemented Task ${task}`);
    }
  }
};

Contents.propTypes = {
  task: PropTypes.string,
  showPreLogin: PropTypes.bool,
  showLogin: PropTypes.bool,
  showLanding: PropTypes.bool,
  showFinished: PropTypes.bool,
  showTaskCompletion: PropTypes.bool,
};

const Test = (props) => (
  <DocumentTitle title={title()}>
    <Contents {...props} />
  </DocumentTitle>
);

export default Test;

Test.propTypes = Contents.propTypes;
