export const INSTRUCTIONS1 = "INSTRUCTIONS1";
export const INSTRUCTIONS2 = "INSTRUCTIONS2";
export const PRESENTATION = "PRESENTATION";
export const PAIRS = "PAIRS";

const LIST_STEPS = [INSTRUCTIONS1, PRESENTATION, INSTRUCTIONS2, PAIRS];
const LISTLESS_STEPS = [INSTRUCTIONS1, PAIRS];

// all available steps
export const ALL_STEPS = LIST_STEPS;

export default (params) =>
  params && params.list ? LIST_STEPS : LISTLESS_STEPS;
