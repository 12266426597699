import { PropTypes } from 'prop-types';
import Button from './button';
import language from '../util/language';
import Spinner from './spinner';

const LandingPage = ({ onSubmit, submitDisabled = false }) => (
  <div className="row">
    {submitDisabled ? (
      <div>
        <Spinner /> <br />{' '}
        <p style={{ textAlign: 'center' }}>Loading Tests...</p>
      </div>
    ) : (
      <div className="small-12 columns instructions">
        <p>{language('landing-page-message-1')}</p>
        <p>{language('landing-page-message-2')}</p>
        <p>{language('landing-page-message-3')}</p>
        <p>{language('landing-page-message-4')}</p>
        <p>{language('landing-page-message-5')}</p>
        <Button onClick={() => onSubmit()} disabled={submitDisabled}>
          {language('start-test-button-label')}
        </Button>
      </div>
    )}
  </div>
);

LandingPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
};

export default LandingPage;
