import { NEXT_TEST } from "../../constants/action-types";
import { TASK_DATA_KEY } from "../../util/task";

// standard params for a task that has a list of tests through which it iterates
export default (
  { testIndex = 0, ...state },
  { type },
  taskType,
  tasks,
  iteration
) => {
  switch (type) {
    case NEXT_TEST:
      const taskData = tasks[TASK_DATA_KEY[taskType]];
      const iterationData = iteration
        ? taskData.iterations[iteration]
        : taskData;
      const tests = iterationData.tests;
      const nextTestIndex =
        testIndex >= tests.length - 1 ? testIndex : testIndex + 1;
      return { ...state, testIndex: nextTestIndex };
    default:
      return { ...state, testIndex };
  }
};
