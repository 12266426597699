import { connect } from "react-redux";
import LandingPageComponent from "../components/landing-page";
import { setLandingShown, markCogTestStart } from "../actions/index";
import maximize from "../util/maximize";

export default connect(
  ({ retrievingTestSession }) => ({ submitDisabled: retrievingTestSession }),
  (dispatch) => ({
    onSubmit: () => {
      maximize();
      dispatch(setLandingShown());
      dispatch(markCogTestStart());
    },
  })
)(LandingPageComponent);
