import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import SendEchoSignal from '../../util/send_echo_signal';

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

class FacialRecognitionTests extends XlntTestComponent {
  triggerHit(keyUpTime, response) {
    this.stop();
    const tapDuration = (keyUpTime - this.keyDownTime) / 1000;
    const reactionTime = (this.keyDownTime - this.startedAt) / 1000;
    this.props.onHit(response, reactionTime, tapDuration);
  }

  start() {
    SendEchoSignal('H');
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 1000;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return (
      [LEFT_ARROW, RIGHT_ARROW].includes(event.keyCode) && !this.ignoreKeys
    );
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now, event.keyCode == LEFT_ARROW ? 'first' : 'second');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.needsRestart = true;
  }

  render() {
    return this.state.active ? (
      <div id="facialrecognition" className={this.state.active ? 'active' : ''}>
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="facialrecognition">
          <img
            id="firstface"
            src={require(`../../../images/tasks/emotion/${this.props.firstImage}.jpg`)}
          />
          <img
            id="secondface"
            src={require(`../../../images/tasks/emotion/${this.props.secondImage}.jpg`)}
          />
        </div>
      </div>
    ) : null;
  }
}

export default FacialRecognitionTests;

FacialRecognitionTests.propTypes = {
  firstImage: PropTypes.string.isRequired,
  secondImage: PropTypes.string.isRequired,
  timeToHit: PropTypes.number.isRequired, // in seconds

  // called when the user presses the key
  // args: response ("first" or "second"), reactionTime, and tapDuration
  onHit: PropTypes.func.isRequired,

  // called when the user fails to respond before the timeToHit
  // no args
  onTimeout: PropTypes.func.isRequired,

  // called when the first test begins
  // no args
  onStart: PropTypes.func,
};
