import { Provider, connect } from 'react-redux';
import Test from './test';
import { generateReport } from '../util/report';
import language from '../util/language';
import Button from '../components/button';
import { getQueryStringParam } from '../util/browser-support';

const DevTools = __DEV__ ? require('./dev-tools').default : null;

const FastForwarder = (ENABLE_FAST_FORWARD || getQueryStringParam("FastForwarder", false))
  ? require('./dev-tools/fast-forwarder').default
  : null;

// temporary, while reporting is programmed
const ReportButton = connect((state) => ({
  onClick: () => generateReport(state).then((report) => console.log(report)),
  style: {
    position: 'fixed',
    top: '50px',
    right: '10px',
  },
}))(({ onClick, style }) => (
  <div style={style}>
    <Button onClick={onClick}>{language('log-report-button-label')}</Button>
  </div>
));

const Root = ({ store }) => {
  const ff = FastForwarder && <FastForwarder />;
  const devTools = DevTools && <DevTools />;
  const reportButton = __DEV__ ? <ReportButton /> : null;
  return (
    <Provider store={store}>
      <div>
        <Test />
        {ff}
        {reportButton}
        {devTools}
      </div>
    </Provider>
  );
};

export default Root;
