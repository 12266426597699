import { createSelector } from "reselect";
import { iterationParams } from "../util/task";
import { firstStep, nextStep } from "../util/task-step";

const getCurrentTask = (state) => state.currentTask || {};

// OPTIMIZE: this returns a different object whenever a result is changed,
//   making dependent, memoized selectors recalculate
//   move results to the top level of the state to fix
const getTasksSessionParams = (state) => state.tasks || {};

const getTaskOrder = (state) => state.tasks.order || [];

export const getCurrentTaskParams = createSelector(
  [getCurrentTask, getTaskOrder],
  ({ taskOrderIndex, ...currentTask }, order) =>
    taskOrderIndex === undefined
      ? currentTask
      : { ...currentTask, ...(order[taskOrderIndex] || {}) }
);

// gets tasks session params for the current task and iteration if there is one
export const getCurrentIterationParams = createSelector(
  [getCurrentTaskParams, getTasksSessionParams],
  (currentTaskParams, tasksSessionParams) => {
    if (!currentTaskParams) {
      return null;
    }
    const { task, iteration } = currentTaskParams;
    return iterationParams(task, iteration, tasksSessionParams);
  }
);

export const getCurrentStep = createSelector(
  [getCurrentTaskParams, getCurrentIterationParams],
  (params, iParams) => {
    if (!params) {
      return null;
    }
    return (params && params.step) || firstStep(params.task, iParams);
  }
);

export const getNextStep = createSelector(
  [getCurrentTaskParams, getCurrentStep, getCurrentIterationParams],
  (params, step, iParams) => params && nextStep(params.task, step, iParams)
);

// returns true if the user is on the last task
export const onLastTask = createSelector(
  [getCurrentTask, getTaskOrder],
  ({ taskOrderIndex }, order) => taskOrderIndex === order.length - 1
);
