import { PropTypes } from 'prop-types';
import XlntTestComponent from '../xlnt-test-component';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const SPACE_BAR = 32;

class TimeTests extends XlntTestComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      finished: false,
      showInterstitial: true,
      timeLeft: props.timeToHit,
    };
  }

  triggerHit(keyUpTime) {
    this.stop();
    const response = (this.keyDownTime - this.startedAt) / 1000;
    this.props.onHit(response);
    if (this._isMounted) {
      this.setState({ showInterstitial: false });
    }
  }

  stop() {
    SendEchoSignal('H');
    this.setState({ active: false, timeLeft: 0 });
    this.ignoreKeys = true;
    clearInterval(this.intervalID);
    this.intervalID = null;
    if (this.props.testIndex == this.props.tests.length - 1) {
      this.setState({ finished: true });
    }
  }

  start() {
    SendEchoSignal('G');
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 1000;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();

    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return event.keyCode == SPACE_BAR && !this.ignoreKeys;
  }

  handleEvent(event) {
    if (
      !this.state.active &&
      event.type == 'keyup' &&
      event.keyCode == SPACE_BAR
    ) {
      event.preventDefault();
      this.start();
    } else if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null && !this.state.finished && this._isMounted) {
        this.triggerHit(now);
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('keydown', this);
    window.addEventListener('keyup', this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.testIndex != nextProps.testIndex && this.state.active) {
      this.needsRestart = true;
    }

    this.setState({ showInterstitial: true });
  }

  render() {
    return this.state.active ? (
      <div id="time"></div>
    ) : !this.state.finished && this.state.showInterstitial ? (
      <div id="time">
        <span className="interval">
          {language('time-estimation-instructions-start-task-message-1')}
        </span>
        <br />
        <span className="interval">
          {language(
            'time-estimation-instructions-start-task-message-2'
          ).replace('[[time]]', this.props.tests[this.props.testIndex])}
        </span>
      </div>
    ) : null;
  }
}

export default TimeTests;

TimeTests.propTypes = {
  // array of times in seconds
  tests: PropTypes.arrayOf(PropTypes.number).isRequired,

  // index of the current test in the tests array
  testIndex: PropTypes.number.isRequired,

  // one argument, the difference between the guessed time and the tested time
  onHit: PropTypes.func.isRequired,

  // called when the first test begins, no arguments
  onStart: PropTypes.func,
};
