import { connect } from "react-redux";
import TestComponent from "../../components/tapping/test";
import {
  nextTask,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
} from "../../actions/index";

const dispatchProps = (dispatch) => ({
  onStart: () => dispatch(markTaskResultsStart()),
  onEnd: (testDetails) => {
    dispatch(addTaskResultDetails(testDetails));
    setTimeout(() => {
      dispatch(markTaskResultsEnd());
      dispatch(setShowTaskCompletion(true, nextTask()));
    }, 1000);
  },
});

const Test = connect(undefined, dispatchProps)(TestComponent);

export default Test;
