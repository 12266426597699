import { connect } from "react-redux";
import PreLoginComponent from "../components/pre-login";
import { retrieveLanguage } from "../actions/index";
import maximize from "../util/maximize";

export default connect(
  ({ preLoginShown }) => ({ preLoginShown: preLoginShown }),
  (dispatch) => ({
    onLoad: () => {
      dispatch(retrieveLanguage());
    },
  })
)(PreLoginComponent);
