import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, { INSTRUCTIONS, TESTS } from '../constants/time-steps';
import Instructions from '../containers/time/instructions';
import Tests from '../containers/time/tests';

const Time = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = <Tests />;
      break;
  }
  return <DocumentTitle title={title(`Time Test`)}>{content}</DocumentTitle>;
};

export default Time;

Time.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
