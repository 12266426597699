import { PropTypes } from 'prop-types';
import Button from '../button';
import language from '../../util/language';

const style = {
  position: 'fixed',
  top: '10px',
  right: '10px',
  display: 'flex',
  gap: '22px',
};

const FastForwarder = ({ nextTask, nextStep }) => {
  const nextStepButton = nextStep ? (
    <Button onClick={nextStep}>{language('next-step-button-label')}</Button>
  ) : null;

  const nextTaskButton = nextTask ? (
    <Button onClick={nextTask}>{language('next-task-button-label')}</Button>
  ) : null;

  return (
    <div id="fost-forwarder" style={style}>
      {nextStepButton}
      {nextTaskButton}
    </div>
  );
};

export default FastForwarder;

FastForwarder.propTypes = {
  nextTask: PropTypes.func,
  nextStep: PropTypes.func,
};
