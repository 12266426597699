// copied from lodash
export const chunk = (array, count) => {
  if (count == null || count < 1) return [];

  let result = [];
  let i = 0,
    length = array.length;
  while (i < length) {
    result.push(array.slice(i, (i += count)));
  }
  return result;
};
