import questionnaireParams from "./questionnaire-params";
import testsTaskParams from "./tests-task-params";
import * as taskTypes from "../../constants/task-types";
import { SET_SHOW_TASK_COMPLETION } from "../../constants/action-types";

// extra params associated with different task types
// e.g. questionnaire task has a page number
const params = ({ taskOrderIndex, ...state }, action, tasks) => {
  if (taskOrderIndex == null) {
    return state;
  }

  const { type: actionType, showTaskCompletion } = action;
  const newState =
    actionType == SET_SHOW_TASK_COMPLETION
      ? { ...state, showTaskCompletion }
      : { ...state };

  const taskType = tasks.order[taskOrderIndex].task;
  const iteration = tasks.order[taskOrderIndex].iteration;
  let paramsFunc;
  switch (taskType) {
    case taskTypes.QUESTIONNAIRE_TASK:
      return questionnaireParams(newState, action, tasks);
    case taskTypes.EMOTION_IDENTIFICATION_TASK:
    case taskTypes.WORD_TASK:
    case taskTypes.TIME_TASK:
    case taskTypes.STOP_OR_GO_TASK:
    case taskTypes.ONE_BACK_TASK:
    case taskTypes.SENTENCE_TASK:
    case taskTypes.FACIAL_RECOGNITION_TASK:
      return testsTaskParams(newState, action, taskType, tasks, iteration);
    default:
      return newState;
  }
};

export default params;
