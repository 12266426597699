import language from '../../util/language';
// work-around for https://github.com/facebook/react/issues/554

const ZeroToFourQuestion = ({ value, onChange, name }) => {
  // onChange doesn't work on IE, so we add a bunch of extra handlers
  // not ideal, because events get fired twice on other browsers
  // see https://github.com/facebook/react/issues/554
  const changeHandler = (e) => onChange(parseInt(e.target.value));
  const mouseMoveHandler = (e) => {
    if (e.buttons == 1 || e.which == 1) {
      changeHandler(e);
    }
  };

  var valueToUse = -1;
  if (value !== undefined && value != null) {
    valueToUse = value;
  }
  return (
    <div>
      <input
        type="range"
        min="-1"
        max="3"
        onChange={changeHandler}
        onClick={changeHandler}
        onKeyDown={changeHandler}
        onMouseMove={mouseMoveHandler}
        value={valueToUse}
        name={name}
      />
      <ul className="zero-to-four-labels">
        <li></li>
        <li>{language('questionnaire-option-never')}</li>
        <li>{language('questionnaire-option-sometimes')}</li>
        <li>{language('questionnaire-option-often')}</li>
        <li>{language('questionnaire-option-very-often')}</li>
      </ul>
    </div>
  );
};

export default ZeroToFourQuestion;
