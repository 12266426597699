import { PropTypes } from 'prop-types';
const CustomProgressBar = ({ completed, color, height }) => {
  if (completed < 0) {
    completed = 0;
  }
  if (completed > 100) {
    completed = 100;
  }

  const style = {
    backgroundColor: color || '#540077',
    width: `${completed}%`,
    transition: 'width 1ms',
    height: height || '10px',
  };

  return (
    <div className="progressbar-container">
      <div className="progressbar-progress" style={style}></div>
    </div>
  );
};

CustomProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  color: PropTypes.string,
  height: PropTypes.string,
};

export default CustomProgressBar;
