import {
  NEXT_TASK,
  SET_SUBMIT_REPORT_ERROR,
  SET_SUBMIT_REPORT_COMPLETE,
} from "../constants/action-types";
import params from "./current-task/params";
import step from "./current-task/step";
import { iterationParams } from "../util/task";

const FINISHED_STATE = { finished: true };

export default (state = {}, action, tasks) => {
  const { taskOrderIndex } = state;
  const { type } = action;
  const { order: taskOrder } = tasks;
  switch (type) {
    case NEXT_TASK: {
      if (state == FINISHED_STATE) {
        return state;
      }
      const nextIndex = taskOrderIndex == null ? 0 : taskOrderIndex + 1;
      if (nextIndex == taskOrder.length) {
        return FINISHED_STATE;
      }
      // state for params is wiped clean when the task changes
      const newParams = params({ taskOrderIndex: nextIndex }, action, tasks);
      const { task, iteration } = tasks.order[nextIndex];
      return {
        taskOrderIndex: nextIndex,
        ...newParams,
        step: step(
          undefined,
          action,
          task,
          iterationParams(task, iteration, tasks)
        ),
      };
    }
    case SET_SUBMIT_REPORT_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        errorMessageDetail: action.detail,
      };
    case SET_SUBMIT_REPORT_COMPLETE:
      return {
        ...state,
        complete: action.complete,
      };
    default: {
      const { task, iteration } = tasks.order[taskOrderIndex] || {};
      return {
        ...state,
        ...params(state, action, tasks),
        step: step(
          state.step,
          action,
          task,
          iterationParams(task, iteration, tasks)
        ),
      };
    }
  }
};
