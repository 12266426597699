import { PropTypes } from 'prop-types';
import KeyListener from '../key-listener';
import Instructions from '../instructions';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const TimeInstructions = (props) => {
  const steps = [
    [
      language('time-estimation-instructions-message-1'),
      <div className="image-with-word-1">
        <img src={require('../../../images/instructions/time-1-a.png')} />
        <span>{language('time-estimation-instructions-image-word')}</span>
      </div>,
    ],
    [
      <span
        dangerouslySetInnerHTML={{
          __html: language('time-estimation-instructions-message-2'),
        }}
      ></span>,
      <div className="image-with-word-2">
        <img src={require('../../../images/instructions/time-2-a.png')} />
        <span>{language('time-estimation-instructions-image-word')}</span>
      </div>,
    ],
    [
      language('time-estimation-instructions-message-3'),
      <img src={require('../../../images/instructions/time-4.png')} />,
    ],
    [
      language('time-estimation-instructions-message-4'),
      <img src={require('../../../images/instructions/spacebar-starts.png')} />,
    ],
  ];

  const onNextFunc = () => {
    SendEchoSignal('FFG');
    props.onNext();
  };

  const video = (
    <iframe
      width="800"
      height="540"
      src="https://www.youtube.com/embed/3Kl--Pc-TSc?si=9-ezF27aEOIdN2-O"
      title="Time Estimation Test Video"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );

  return (
    <KeyListener keyCode={32} onKey={onNextFunc}>
      <Instructions
        title={language('time-estimation-title')}
        steps={steps}
        id="time-test-instruction-page"
        video={video}
      />
    </KeyListener>
  );
};

export default TimeInstructions;

TimeInstructions.propTypes = {
  onNext: PropTypes.func.isRequired,
};
