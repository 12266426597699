import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import language from '../../util/language';
import SendEchoSignal from '../../util/send_echo_signal';

const SPACE_BAR = 32;

class StopOrGoTests extends XlntTestComponent {
  start() {
    if (this.props.iteration == 'by_word') {
      const test = this.props.tests[this.props.testIndex];
      if (test.value == 'Stop') {
        SendEchoSignal('H');
      } else {
        SendEchoSignal('G');
      }
    }

    if (this.props.iteration == 'by_color') {
      const test = this.props.tests[this.props.testIndex];
      if (test.value == 'Blue') {
        SendEchoSignal('G');
      } else {
        SendEchoSignal('H');
      }
    }
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 1000;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return event.keyCode == SPACE_BAR && !this.ignoreKeys;
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.testIndex != nextProps.testIndex) {
      this.needsRestart = true;
    }
  }

  render() {
    const test = this.props.tests[this.props.testIndex];
    const cssClass =
      this.props.iteration == 'by_word' ? test.color : test.value;
    const value = this.props.iteration == 'by_word' ? test.value : test.word;
    return this.state.active ? (
      <div id="stop-or-go" className={this.state.active ? 'active' : ''}>
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="stop-or-go">
          <span className={`stop-or-go-word ${cssClass}`}>
            {language('stop-or-go-' + value.toLowerCase())}
          </span>
        </div>
      </div>
    ) : null;
  }
}

export default StopOrGoTests;

StopOrGoTests.propTypes = {
  iteration: PropTypes.oneOf(['by_word', 'by_color']).isRequired,

  // array of test parameters
  // if the iteration is by_word, parameters are { color: Color, value: Word }
  // if the iteration is by_color, parameters are { value: Color, word: Word }
  // where Color is "Red" or "Blue"
  // and Word is "Stop" or "Go"
  tests: PropTypes.arrayOf(PropTypes.object).isRequired,

  // index of the current test in the tests array
  testIndex: PropTypes.number.isRequired,

  timeToHit: PropTypes.number.isRequired, // in seconds

  // called when the user presses the key
  // args: reactionTime and tapDuration
  onHit: PropTypes.func.isRequired,

  // no arguments
  onTimeout: PropTypes.func.isRequired,

  // called when the first test begins, no arguments
  onStart: PropTypes.func,
};
