import { useEffect } from 'react';
import { PropTypes } from 'prop-types';

const KeyListener = ({ keyCode, onKey, children }) => {
  const handleEvent = (event) => {
    if (event.keyCode === keyCode) {
      if (event.type === 'keyup') {
        event.preventDefault();
        onKey();
      } else if (event.type === 'keydown') {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    // apparently keypress event doesn't work in all browsers for arrow keys
    // so we use keyup instead
    window.addEventListener('keyup', handleEvent);
    // listen to keydown too, to prevent the default handling
    window.addEventListener('keydown', handleEvent);

    return () => {
      window.removeEventListener('keyup', handleEvent);
      window.removeEventListener('keydown', handleEvent);
    };
  }, [keyCode, onKey]);

  return <div>{children}</div>;
};

KeyListener.propTypes = {
  keyCode: PropTypes.number.isRequired,
  onKey: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default KeyListener;
