import {
  SET_TASK_RESULTS_START,
  SET_TASK_RESULTS_END,
  ADD_TASK_RESULT_DETAILS,
  SET_TASK_RESULT_DETAILS,
  SET_COG_TEST_START,
  SET_COG_TEST_END,
} from "../../constants/action-types";
import { TASK_DATA_KEY } from "../../util/task";

const updateTask = (state, taskType, iteration, func) => {
  const taskKey = TASK_DATA_KEY[taskType];
  const taskResults = state[taskKey] || {};
  let newTaskResults;
  if (iteration) {
    newTaskResults = {
      ...taskResults,
      [iteration]: func(taskResults[iteration] || {}),
    };
  } else {
    newTaskResults = func(taskResults);
  }
  return {
    ...state,
    [taskKey]: newTaskResults,
  };
};

export default (
  state = { cogTestStart: null, cogTestEnd: null },
  { type, ...action },
  taskType,
  iteration
) => {
  const curriedUpdateTask = (func) =>
    updateTask(state, taskType, iteration, func);
  const addDetailsFunc = (i) => {
    const detailsArray = Array.isArray(action.details)
      ? action.details
      : [action.details];
    return {
      ...i,
      details: [...(i.details || []), ...detailsArray],
    };
  };
  switch (type) {
    case SET_COG_TEST_START:
      return { ...state, cogTestStart: action.time };
    case SET_COG_TEST_END:
      return { ...state, cogTestEnd: action.time };
    case SET_TASK_RESULTS_START:
      return curriedUpdateTask((i) => ({ ...i, startTime: action.time }));
    case SET_TASK_RESULTS_END:
      return curriedUpdateTask((i) => ({ ...i, endTime: action.time }));
    case ADD_TASK_RESULT_DETAILS:
      return curriedUpdateTask(addDetailsFunc);
    case SET_TASK_RESULT_DETAILS:
      return curriedUpdateTask((i) => {
        const index = (i.details || []).findIndex(action.equalityFunc);
        if (index != -1) {
          return {
            ...i,
            details: [
              ...i.details.slice(0, index),
              action.details,
              ...i.details.slice(index + 1),
            ],
          };
        } else {
          return addDetailsFunc(i);
        }
      });
    default:
      return state;
  }
};
