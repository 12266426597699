import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import STEPS, { INSTRUCTIONS, TESTS } from '../constants/tapping-steps';
import Instructions from '../containers/tapping/instructions';
import Test from '../containers/tapping/test';

const Tapping = ({ step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS:
      content = <Instructions />;
      break;
    case TESTS:
      content = <Test />;
      break;
  }
  return <DocumentTitle title={title(`Tapping Test`)}>{content}</DocumentTitle>;
};

export default Tapping;

Tapping.propTypes = {
  step: PropTypes.oneOf(STEPS).isRequired,
};
