import { connect } from "react-redux";
import TestsComponent from "../../components/sentence/tests";
import {
  nextTask,
  nextTest,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
} from "../../actions/index";

const stateToProps = (state) => {
  const {
    currentTask: { testIndex },
    tasks: {
      sentence: { tests },
    },
  } = state;
  return { tests, testIndex };
};

const dispatchProps = {
  nextTest,
  setShowTaskCompletion,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
};

const mergeProps = (
  stateProps,
  {
    nextTest: boundNextTest,
    setShowTaskCompletion: boundSetShowTaskCompletion,
    markTaskResultsStart: boundMarkTaskResultsStart,
    markTaskResultsEnd: boundMarkTaskResultsEnd,
    addTaskResultDetails: boundAddTaskResultDetails,
  },
  otherProps
) => {
  const { testIndex, tests } = stateProps;
  const lastTest = testIndex === tests.length - 1;
  const next = () =>
    setTimeout(
      () => {
        if (lastTest) {
          boundMarkTaskResultsEnd();
          boundSetShowTaskCompletion(true, nextTask());
        } else {
          boundNextTest();
        }
      },

      1000
    );
  const handleStart = () => boundMarkTaskResultsStart();
  const handleHit = (id, response, reactionTime, tapDuration) => {
    boundAddTaskResultDetails({
      id,
      response: response,
      reactionTime,
      tapDuration,
    });
    next();
  };

  const handleTimeout = (id) => {
    boundAddTaskResultDetails({ id, response: "not-hit" });
    next();
  };

  return {
    ...stateProps,
    ...otherProps,
    onStart: handleStart,
    onTimeout: handleTimeout,
    onHit: handleHit,
    timeToHit: 1.6,
    timeBetweenWords: 0.65, //0.5,  These are backwards in silverlight
    timeShowingWords: 0.5, //0.65, They are named incorrectly, using silverlight values, keeping sensible naming
  };
};

const Tests = connect(stateToProps, dispatchProps, mergeProps)(TestsComponent);

export default Tests;
