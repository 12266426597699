import { PropTypes } from 'prop-types';
import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import {
  ALL_STEPS,
  INSTRUCTIONS1,
  PRESENTATION,
  INSTRUCTIONS2,
  PAIRS,
} from '../constants/word-steps';
import Instructions from '../containers/word/instructions';
import Presentation from '../containers/word/presentation';
import Pairs from '../containers/word/pairs';

const Word = ({ iteration, step }) => {
  let content;
  switch (step) {
    case INSTRUCTIONS1:
      content = <Instructions timeShown={1} />;
      break;
    case PRESENTATION:
      content = <Presentation />;
      break;
    case INSTRUCTIONS2:
      content = <Instructions timeShown={2} />;
      break;
    case PAIRS:
      content = <Pairs />;
      break;
  }
  return (
    <DocumentTitle title={title(`Word Test ${iteration}`)}>
      {content}
    </DocumentTitle>
  );
};

export default Word;

Word.propTypes = {
  iteration: PropTypes.string.isRequired,
  step: PropTypes.oneOf(ALL_STEPS).isRequired,
};
