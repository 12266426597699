import { connect } from "react-redux";
import { getCurrentTaskParams } from "../../selectors/tasks";

import EmotionPresentationComponent from "../../components/emotion/presenter";
import {
  nextTask,
  nextTest,
  markTaskResultsStart,
  markTaskResultsEnd,
  addTaskResultDetails,
  setShowTaskCompletion,
} from "../../actions/index";

const stateToProps = (state) => {
  const {
    tasks: {
      emotions: { iterations },
    },
  } = state;
  const { testIndex, iteration } = getCurrentTaskParams(state);
  const tests = iterations[iteration].tests;
  return {
    iteration,
    lastEmotion: testIndex == tests.length - 1,
    tests,
    testIndex,
    timeToHit: Math.floor(1.85 * 60),
  };
};

const mergeProps = (
  { lastEmotion, iteration, ...stateProps },
  { dispatch },
  otherProps
) => {
  const next = () =>
    setTimeout(
      () => {
        if (lastEmotion) {
          dispatch(markTaskResultsEnd(iteration, Date.now()));
          dispatch(setShowTaskCompletion(true, nextTask()));
        } else {
          dispatch(nextTest());
        }
      },

      1000
    );
  const handleStart = () => dispatch(markTaskResultsStart());
  return {
    ...stateProps,
    ...otherProps,
    onStart: handleStart,
    onTimeout: () => {
      dispatch(
        addTaskResultDetails({
          response: "not-hit",
          reactionTime: 0,
          tapDuration: 0,
        })
      );
      next();
    },

    onHit: (reactionTime, tapDuration) => {
      dispatch(
        addTaskResultDetails({ response: "hit", reactionTime, tapDuration })
      );
      next();
    },
  };
};

const EmotionPresentation = connect(
  stateToProps,
  (dispatch) => ({ dispatch }),
  mergeProps
)(EmotionPresentationComponent);

export default EmotionPresentation;
