import { PropTypes } from 'prop-types';
import CustomProgressBar from '../custom-progress-bar';
import XlntTestComponent from '../xlnt-test-component';
import SendEchoSignal from '../../util/send_echo_signal';

const SPACE_BAR = 32;

// presentation of faces to be used in the test
// they're presented one at a time
class EmotionPresentation extends XlntTestComponent {
  start() {
    this.startedAt = Date.now();
    this.keyDownTime = null;

    const updateTimeLeft = () => {
      const timeSpent = (Date.now() - this.startedAt) / 10;
      const timeLeft = this.props.timeToHit - timeSpent;
      this.setState({ timeLeft });
      return timeLeft;
    };

    updateTimeLeft();
    this.setState({ active: true });
    this.ignoreKeys = false;
    if (this.props.onStart) {
      this.props.onStart();
    }

    if (this.intervalID) {
      try {
        stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    }

    const onInterval = () => {
      if (!this._isMounted) {
        return;
      }
      const timeLeft = updateTimeLeft();
      if (timeLeft < 0) {
        this.triggerTimeout();
        SendEchoSignal('H');
      }
    };

    this.intervalID = setInterval(onInterval, 40);
  }

  isRelevantKey(event) {
    return event.keyCode == SPACE_BAR && !this.ignoreKeys;
  }

  handleEvent(event) {
    if (event.type == 'keydown' && this.isRelevantKey(event)) {
      this.keyDownTime = Date.now();
      event.preventDefault();
    } else if (event.type == 'keyup' && this.isRelevantKey(event)) {
      const now = Date.now();
      event.preventDefault();
      if (this.keyDownTime != null) {
        this.triggerHit(now);
        SendEchoSignal('G');
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.image != nextProps.image) {
      this.needsRestart = true;
    }
  }

  render() {
    const image = '../../../images/tasks/emotion/' + this.props.image + '.jpg';
    return this.state.active ? (
      <div id="emotion" className={this.state.active ? 'active' : ''}>
        <CustomProgressBar
          completed={parseInt(
            100 * (this.state.timeLeft / this.props.timeToHit)
          )}
          height="30px"
          speed="0.01"
        />
        <div className="emotion">
          <img
            id="face"
            src={require(`../../../images/tasks/emotion/${this.props.image}.jpg`)}
          />
        </div>
      </div>
    ) : null;
  }
}

EmotionPresentation.propTypes = {
  image: PropTypes.string.isRequired,
  timeToHit: PropTypes.number.isRequired, // in centiseconds
  onTimeout: PropTypes.func.isRequired,
  onHit: PropTypes.func.isRequired,
  onStart: PropTypes.func,
};

export default EmotionPresentation;
