import DocumentTitle from '../containers/document-title';
import title from '../util/title';
import language from '../util/language';

const TaskCompletionMessage = () => (
  <DocumentTitle title={title(language('task-completed-title'))}>
    <div id="task-completion-message" className="instructions">
      {language('task-completed-message-1')}
    </div>
  </DocumentTitle>
);

export default TaskCompletionMessage;

TaskCompletionMessage.propTypes = {};
